import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Offcanvas,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  DeleteCompany,
  ExportAnalytics,
  ExportScoring,
  IndexAdmin
} from '../../../../../services/CompanyService';
import classNames from 'classnames';
import PrintComponent from './exportPDF';
import SoftBadge from '../../../../common/SoftBadge';
import moment from 'moment';
import PointCompany from './points/pointCompany';
import { ExportPoints } from '../../../../../services/Point/PointService';
import IconAlert from '../../../../common/IconAlert';

//redux
import { changeSearch } from '../../../../../redux/slices/companiesSlice';
import { useSelector, useDispatch } from 'react-redux';
import ExportScoringCanvas from './exportScoring/offcanvas';
import ExportCompaniesExcel from '../../../../../exports/companiesFullExcelExports';
import ExportAnalyticsCanvas from './exportAnalytics/offcanvas';
// fin import redux

const IndexCompanies = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialCompanies, setInitialCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companiesWithChildrens, setCompaniesWithChildrens] = useState([]);
  const [initialCompaniesWithChildrens, setInitialCompaniesWithChildrens] =
    useState([]);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState('');
  const [exportScoringUrl, setExportScoringUrl] = useState('');
  const [exportAnalyticsUrl, setExportAnalyticsUrl] = useState('');
  const [selectedObject, setSelectedObject] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showExportPDF, setShowExportPDF] = useState(false);
  const [showPointCompany, setShowPointCompany] = useState(false);
  const [companiesToExportPdf, setCompaniesToExportPdf] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchExportInput, setSearchExportInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  //redux
  const dispatch = useDispatch();

  // récupère le search du store persistant de redux
  const searchStored = useSelector(
    state => state.persistedCompaniesReducer.search
  );
  // initialse search avec le search du store
  useEffect(() => {
    setSearchInput(searchStored);
  }, [initialCompaniesWithChildrens]);

  // update le search du store
  useEffect(() => {
    applySearch(searchStored, companies);
  }, [searchStored, companiesWithChildrens]);

  useEffect(() => {
    const fetchCompanies = async () => {
      setIsLoading(true);
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        setInitialCompanies(responseCompanies.data);
        setCompanies(responseCompanies.data);
        if (selectedObject.id) {
          setSelectedObject(
            responseCompanies.data.find(c => c.id === selectedObject.id)
          );
        }
        let tmpCompaniesWithChildrens = [];
        responseCompanies.data.map(c => {
          tmpCompaniesWithChildrens.push(c);
          if (c.childrens && c.childrens.length > 0) {
            c.childrens.map(children =>
              tmpCompaniesWithChildrens.push(children)
            );
          }
        });
        setInitialCompaniesWithChildrens(tmpCompaniesWithChildrens);
        setCompaniesWithChildrens(tmpCompaniesWithChildrens);
        applySearch(searchInput, responseCompanies.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
  }, [trigger]);

  const deleteObject = async () => {
    const response = await DeleteCompany(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Fiche société supprimée');
      setSelectedObject({});
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };
  const applySearch = (value, objects) => {
    if (value.length > 2) {
      let searchCompanies = [];
      let tmpInitialCompanies = [];
      if (objects) {
        tmpInitialCompanies = objects;
      } else {
        tmpInitialCompanies = initialCompanies;
      }
      searchCompanies = tmpInitialCompanies.filter(
        company =>
          (company.childrens.length < 1 &&
            company.name
              .toLowerCase()
              .replace('.', '')
              .indexOf(searchInput.toLowerCase().replace('.', '')) > -1) ||
          (company.childrens.length > 0 &&
            (company.name
              .toLowerCase()
              .replace('.', '')
              .indexOf(searchInput.toLowerCase().replace('.', '')) > -1 ||
              company.childrens.filter(
                c =>
                  c.name
                    .toLowerCase()
                    .replace('.', '')
                    .indexOf(searchInput.toLowerCase().replace('.', '')) > -1
              ).length > 0))
      );
      setCompanies(searchCompanies);
    } else {
      if (objects) {
        setCompanies(objects);
      } else {
        setCompanies(initialCompanies);
      }
    }
  };

  const applySearchExport = value => {
    if (value.length > 2) {
      let searchCompanies = initialCompaniesWithChildrens.filter(
        company =>
          company.name
            .toLowerCase()
            .replace('.', '')
            .indexOf(value.toLowerCase().replace('.', '')) > -1
      );
      setCompaniesWithChildrens(searchCompanies);
    } else {
      setCompaniesWithChildrens(initialCompaniesWithChildrens);
    }
  };

  const exportPoints = async year => {
    setExportIsLoading(true);
    const responseExport = await ExportPoints(year);
    if (responseExport.success === true) {
      setExportIsLoading(false);
      setExportUrl(responseExport.data);
    } else {
      setExportIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const exportScoring = async form => {
    setExportIsLoading(true);
    const responseExport = await ExportScoring(form);
    if (responseExport.success === true) {
      setExportIsLoading(false);
      setExportAnalyticsUrl(responseExport.data);
    } else {
      setExportIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const exportAnalytics = async form => {
    setExportIsLoading(true);
    const responseExport = await ExportAnalytics(form);
    if (responseExport.success === true) {
      setExportIsLoading(false);
      setExportScoringUrl(responseExport.data);
    } else {
      setExportIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialCompanies.length > 0 && (
        <>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {selectedObject.id !== undefined && (
                  <>
                    L'archivage de la fiche société "{selectedObject.name}" est
                    définitif.
                    {selectedObject.childrens !== undefined &&
                      selectedObject.childrens.length > 0 && (
                        <>
                          {' '}
                          {selectedObject.childrens.length > 1
                            ? `Ses ${selectedObject.childrens.length} filiales seront également archivées.`
                            : `Sa filiale sera également archivée.`}
                        </>
                      )}
                  </>
                )}
                <br />
                Voulez-vous continuer ?
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteObject();
                }}
              >
                Oui, archiver
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <Card className="px-lg-3">
            <Card.Body className="overflow-hidden px-lg-3">
              <Row className="justify-content-between align-items-center">
                <Col lg={5}>
                  <h3 className="text-primary admin-title mb-0">
                    Gestion des sociétés
                  </h3>
                </Col>
                <Col xs={7} className="text-end">
                  <DropdownButton
                    as={ButtonGroup}
                    variant="falcon-default"
                    size="sm"
                    title={
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '2px' }}
                        >
                          <path
                            d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                            fill="rgba(75,74,77,1)"
                          />
                        </svg>{' '}
                        Points
                      </>
                    }
                    className="me-2"
                  >
                    {Array.from(
                      { length: new Date().getFullYear() - 2023 + 1 },
                      (_, i) => 2023 + i
                    ).map((year, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={index}
                        onClick={() => {
                          setExportUrl('');
                          exportPoints(year);
                        }}
                        disabled={exportIsLoading === true}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '2px' }}
                        >
                          <path
                            d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                            fill="rgba(75,74,77,1)"
                          />
                        </svg>
                        Export points {year}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <DropdownButton
                    as={ButtonGroup}
                    variant="falcon-default"
                    size="sm"
                    title={
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '2px' }}
                        >
                          <path
                            d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                            fill="rgba(75,74,77,1)"
                          />
                        </svg>{' '}
                        Gestion et exports
                      </>
                    }
                    className="me-2"
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => {
                        setShowExportPDF(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>
                      Exporter les fiches (PDF)
                    </Dropdown.Item>
                    <ExportCompaniesExcel />
                    <ExportScoringCanvas
                      exportIsLoading={exportIsLoading}
                      exportScoring={exportScoring}
                      setExportUrl={setExportUrl}
                    />
                    <ExportAnalyticsCanvas
                      setExportUrl={setExportUrl}
                      exportAnalytics={exportAnalytics}
                      exportIsLoading={exportIsLoading}
                    />
                    <Dropdown.Item
                      eventKey="3"
                      onClick={() => {
                        window.location.href =
                          '/administration/entreprises-exports-excel';
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>
                      Gérer les annuaires
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="3"
                      onClick={() => {
                        window.location.href =
                          '/administration/demarches-environnementales';
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M20.998 3V5C20.998 14.6274 15.6255 19 8.99805 19L5.24077 18.9999C5.0786 19.912 4.99805 20.907 4.99805 22H2.99805C2.99805 20.6373 3.11376 19.3997 3.34381 18.2682C3.1133 16.9741 2.99805 15.2176 2.99805 13C2.99805 7.47715 7.4752 3 12.998 3C14.998 3 16.998 4 20.998 3ZM12.998 5C8.57977 5 4.99805 8.58172 4.99805 13C4.99805 13.3624 5.00125 13.7111 5.00759 14.0459C6.26198 12.0684 8.09902 10.5048 10.5019 9.13176L11.4942 10.8682C8.6393 12.4996 6.74554 14.3535 5.77329 16.9998L8.99805 17C15.0132 17 18.8692 13.0269 18.9949 5.38766C17.6229 5.52113 16.3481 5.436 14.7754 5.20009C13.6243 5.02742 13.3988 5 12.998 5Z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>
                      Gérer les démarches environnementales
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="3"
                      onClick={() => {
                        window.location.href =
                          '/administration/representative-section-types';
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>
                      Gérer les fonctions
                    </Dropdown.Item>
                  </DropdownButton>
                  <Button
                    variant="primary"
                    className="admin-primary"
                    size="sm"
                    as={Link}
                    to="/administration/entreprises/ajouter"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path
                        d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                    Ajouter une fiche
                  </Button>
                </Col>
              </Row>
              <Row>
                {exportIsLoading === true && (
                  <Col xs={12} className="mt-2">
                    <Spinner animation="border" role="status" size="xxs">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                )}
                {exportUrl && (
                  <Col xs={12} className="mt-2">
                    <IconAlert
                      variant="success"
                      dismissible
                      onClose={() => setExportUrl('')}
                      className="mt-3"
                    >
                      <p className="mb-0">
                        Export Excel des points disponible :{' '}
                        <a href={exportUrl}>cliquez ici pour le télécharger</a>
                      </p>
                    </IconAlert>
                  </Col>
                )}
                {exportScoringUrl && (
                  <Col xs={12} className="mt-2">
                    <IconAlert
                      variant="success"
                      dismissible
                      onClose={() => setExportScoringUrl('')}
                      className="mt-3"
                    >
                      <p className="mb-0">
                        Export Excel des scores disponible :{' '}
                        <a href={exportScoringUrl}>
                          cliquez ici pour le télécharger
                        </a>
                      </p>
                    </IconAlert>
                  </Col>
                )}
                {exportAnalyticsUrl && (
                  <Col xs={12} className="mt-2">
                    <IconAlert
                      variant="success"
                      dismissible
                      onClose={() => setExportAnalyticsUrl('')}
                      className="mt-3"
                    >
                      <p className="mb-0">
                        Export Excel des vues d'entreprises disponible :{' '}
                        <a href={exportAnalyticsUrl}>
                          cliquez ici pour le télécharger
                        </a>
                      </p>
                    </IconAlert>
                  </Col>
                )}
              </Row>
              <Row className="justify-content-between align-items-center px-3 mt-5">
                <Col xs={12} className="mb-0 px-0">
                  <Form.Control
                    type="search"
                    className="search-input"
                    placeholder="Rechercher quelque chose..."
                    aria-label="Search"
                    value={searchInput}
                    size="sm"
                    onChange={e => {
                      dispatch(changeSearch(e.target.value));
                      setSearchInput(e.target.value);

                      applySearch(e.target.value);
                    }}
                  />
                </Col>
                {companies.map((company, index) => (
                  <Fragment key={index}>
                    <Col className="company-list mt-4" key={index} xs={12}>
                      <Row className="align-items-center py-2">
                        <Col xs={1} className="text-center">
                          <img src={company.logo_url} className="mw-100" />
                        </Col>
                        <Col xs={6} className="px-0">
                          <div className="d-block mb-0">
                            {company.name}
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="overlay-trigger-example">
                                  Points pour {moment().format('YYYY')}
                                </Tooltip>
                              }
                            >
                              <span
                                className="d-inline cursor-pointer"
                                onClick={() => {
                                  setSelectedObject(company);
                                  setShowPointCompany(true);
                                }}
                              >
                                <SoftBadge
                                  pill
                                  bg={company.colorPoints}
                                  className="ms-2 badge-points"
                                >
                                  {company.totalPoints} points
                                </SoftBadge>
                              </span>
                            </OverlayTrigger>
                            {company.fill_request_uuid?.length > 1 ? (
                              <small>
                                {' '}
                                <small>
                                  https://www.tredunion.fr/edition-donnees-entreprise/
                                  {company.fill_request_uuid}{' '}
                                  {company.fill_request_completed_at?.length >
                                  1 ? (
                                    <b style={{ color: 'green' }}>COMPLÉTÉ</b>
                                  ) : (
                                    <b style={{ color: 'orange' }}>
                                      EN ATTENTE
                                    </b>
                                  )}
                                </small>
                              </small>
                            ) : (
                              ''
                            )}
                          </div>
                        </Col>
                        <Col xs={5} className="text-end">
                          <Button
                            size="sm"
                            variant="primary"
                            className="me-2 admin-primary"
                            type="button"
                            onClick={() => {
                              window.location.href = `/administration/entreprises/${company.id}/modifier`;
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '2px',
                                marginRight: '3px'
                              }}
                            >
                              <path
                                d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Éditer
                          </Button>
                          <Button
                            size="sm"
                            variant="danger"
                            className="me-0"
                            type="button"
                            onClick={() => {
                              setShowDelete(true);
                              setSelectedObject(company);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    {company.childrens !== null &&
                      company.childrens !== undefined &&
                      company.childrens.length > 0 && (
                        <>
                          <Col xs={12}></Col>
                          {company.childrens.map((child, indexChild) => (
                            <Col xs={11} className="offset-1" key={indexChild}>
                              <Row>
                                <Col
                                  className="company-list mt-2"
                                  key={index}
                                  xs={12}
                                >
                                  <Row className="align-items-center py-2">
                                    <Col xs={1} className="text-center">
                                      <img
                                        src={child.logo_url}
                                        className="mw-100"
                                      />
                                    </Col>
                                    <Col xs={6} className="px-0">
                                      <p className="mb-0">{child.name}</p>
                                      {child.fill_request_uuid?.length > 1 ? (
                                        <small>
                                          {' '}
                                          <small>
                                            https://www.tredunion.fr/edition-donnees-entreprise/
                                            {child.fill_request_uuid}{' '}
                                            {child.fill_request_completed_at
                                              ?.length > 1 ? (
                                              <b style={{ color: 'green' }}>
                                                COMPLÉTÉ
                                              </b>
                                            ) : (
                                              <b style={{ color: 'orange' }}>
                                                EN ATTENTE
                                              </b>
                                            )}
                                          </small>
                                        </small>
                                      ) : (
                                        ''
                                      )}
                                    </Col>
                                    <Col xs={5} className="text-end">
                                      <Button
                                        size="sm"
                                        variant="primary"
                                        className="me-2 admin-primary"
                                        type="button"
                                        onClick={() => {
                                          window.location.href = `/administration/entreprises/${child.id}/modifier`;
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="14"
                                          height="14"
                                          style={{
                                            marginBottom: '2px',
                                            marginRight: '3px'
                                          }}
                                        >
                                          <path
                                            d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                                            fill="rgba(255,255,255,1)"
                                          />
                                        </svg>
                                        Éditer
                                      </Button>
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        className="me-0"
                                        type="button"
                                        onClick={() => {
                                          setShowDelete(true);
                                          setSelectedObject(child);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="14"
                                          height="14"
                                          style={{
                                            marginBottom: '2px'
                                          }}
                                        >
                                          <path
                                            d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                            fill="rgba(255,255,255,1)"
                                          />
                                        </svg>
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </>
                      )}
                  </Fragment>
                ))}
              </Row>
            </Card.Body>
          </Card>
          <Offcanvas
            show={showExportPDF}
            onHide={() => {
              setShowExportPDF(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                Exporter les fiches au format PDF
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Row className="mb-4">
                <Col xs={12} className="mb-3">
                  <Form.Control
                    type="search"
                    className="search-input"
                    placeholder="Rechercher quelque chose..."
                    aria-label="Search"
                    value={searchExportInput}
                    size="sm"
                    onChange={e => {
                      setSearchExportInput(e.target.value);
                      applySearchExport(e.target.value);
                    }}
                  />
                </Col>
                {searchExportInput.length < 2 && (
                  <Col xs={12}>
                    <label className="cursor-pointer">
                      <input
                        type="checkbox"
                        className="me-1 cursor-pointer"
                        checked={
                          companiesToExportPdf.length ===
                          companiesWithChildrens.length
                        }
                        onChange={event => {
                          if (event.target.checked) {
                            setCompaniesToExportPdf(
                              companiesWithChildrens.map(c => c.id)
                            );
                          } else {
                            setCompaniesToExportPdf([]);
                          }
                        }}
                      />
                      <b>Tout sélectionner</b>
                    </label>
                  </Col>
                )}
                {companiesWithChildrens.map((company, index) => (
                  <Col xs={12} key={index}>
                    <label
                      className={classNames('cursor-pointer', {
                        'ps-3':
                          company.headquarter_id !== null &&
                          company.headquarter_id !== undefined
                      })}
                    >
                      <input
                        type="checkbox"
                        name="exportCompanies[]"
                        value={company.id}
                        className="me-1 cursor-pointer"
                        checked={companiesToExportPdf.includes(company.id)}
                        onChange={event => {
                          if (event.target.checked) {
                            if (
                              !companiesToExportPdf.includes(event.target.value)
                            ) {
                              setCompaniesToExportPdf([
                                ...companiesToExportPdf,
                                parseInt(event.target.value)
                              ]);
                            }
                          } else {
                            setCompaniesToExportPdf(
                              companiesToExportPdf.filter(
                                c => c !== parseInt(event.target.value)
                              )
                            );
                          }
                        }}
                      />
                      {company.name}
                    </label>
                  </Col>
                ))}
                {searchExportInput.length < 2 && (
                  <Col xs={12}>
                    <label className="cursor-pointer">
                      <input
                        type="checkbox"
                        className="me-1 cursor-pointer"
                        checked={
                          companiesToExportPdf.length ===
                          companiesWithChildrens.length
                        }
                        onChange={event => {
                          if (event.target.checked) {
                            setCompaniesToExportPdf(
                              companiesWithChildrens.map(c => c.id)
                            );
                          } else {
                            setCompaniesToExportPdf([]);
                          }
                        }}
                      />
                      <b>Tout sélectionner</b>
                    </label>
                  </Col>
                )}
              </Row>
              <Row
                style={{
                  position: 'fixed',
                  bottom: '0',
                  paddingBottom: '13px',
                  background:
                    'linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 60%)'
                }}
              >
                <PrintComponent
                  companies={companiesWithChildrens}
                  selectedIds={companiesToExportPdf}
                />
              </Row>
            </Offcanvas.Body>
          </Offcanvas>
          <PointCompany
            companyComponent={selectedObject}
            showPointCompany={showPointCompany}
            setShowPointCompany={setShowPointCompany}
            setParentTrigger={setTrigger}
          />
        </>
      )}
    </>
  );
};

export default IndexCompanies;
