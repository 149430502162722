import React, { Fragment, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IndexAdmin } from '../../../../../services/CompanyService';
import { useParams } from 'react-router';
import FileUploader from '../../../commons/fileUploader';
import FormAlert from '../../../commons/FormAlert';
import Select from 'react-select';
import {
  DeleteUser,
  PostUser,
  PutUser,
  ShowUserAdmin
} from '../../../../../services/UserService';
import IconAlert from '../../../../common/IconAlert';
import SoftBadge from '../../../../common/SoftBadge';
import { IndexRoles } from '../../../../../services/RoleService';
import { IndexCourse } from '../../../../../services/Elearning/CourseService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ManageUserToEvent } from '../../../../../services/Event/EventService';
import moment, * as Moment from 'moment';
import { ManageUserToTemplate } from '../../../../../services/RecipientTemplateService';

import menAvatar from 'assets/img/men.png';
import womenAvatar from 'assets/img/women.png';
import { IndexRepresentativeSectionType } from '../../../../../services/RepresentativeSectionTypeService';

const ManageUsers = () => {
  const [trigger] = useState(Date.now());
  const [roles, setRoles] = useState([]);
  const [courses, setCourses] = useState([]);
  const [originalCourses, setOriginalCourses] = useState([]);
  const [companiesSelect, setCompaniesSelect] = useState([]);
  const [user, setUser] = useState({});
  const { id } = useParams();
  const [insertedId, setInsertedId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showWarningEvent, setShowWarningEvent] = useState(false);
  const [returnedObject, setReturnedObject] = useState({});
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [types, setTypes] = useState([]);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    login: '',
    email: '',
    gender: null,
    birthdate: '',
    phone: '',
    mobile_phone: '',
    profile_picture_url: '',
    occupation: '',
    main_company_id: '',
    main_company_invoices_recipient: 0,
    secondary_companies: [],
    roles: [],
    notifications: [],
    signature_file_url: '',
    is_admin: 0,
    calendar_access: 1,
    course_id: '',
    courses: [],
    user_representatives: []
  });
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showActivities, setShowActivities] = useState(false);

  useEffect(() => {
    const fetchTypes = async () => {
      const response = await IndexRepresentativeSectionType();
      if (response.success === true) {
        setTypes(response.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchTypes();
    const fetchUser = async () => {
      const responseUser = await ShowUserAdmin(id);
      if (responseUser.success === true) {
        let dataUser = responseUser.data;
        setUser(dataUser);
        let companiesArray = [];
        dataUser.secondary_companies.map(company => {
          companiesArray.push({
            value: `${company.id}`,
            label: company.name,
            invoices_recipient: company.pivot.invoices_recipient
          });
        });
        let rolesArray = [];
        dataUser.roles.map(role => {
          rolesArray.push({
            value: `${role.id}`,
            label: role.title
          });
        });
        let notificationsArray = [];
        dataUser.notifications.map(role => {
          notificationsArray.push({
            value: `${role.id}`,
            label: role.title
          });
        });
        let coursesArray = [];
        dataUser.courses.map(course => {
          coursesArray.push({
            value: `${course.id}`,
            label: course.title,
            step_id:
              dataUser.courses.length > 0 &&
              dataUser.courses.filter(c => c.id === course.id)[0] &&
              dataUser.courses.filter(c => c.id === course.id)[0].pivot &&
              dataUser.courses.filter(c => c.id === course.id)[0].pivot
                .course_current_step_id
                ? dataUser.courses.filter(c => c.id === course.id)[0].pivot
                    .course_current_step_id
                : ''
          });
        });
        let representativeArray = [];
        dataUser.main_company?.company_representative_sections?.map(section => {
          if (section.users?.find(u => parseInt(u.id) === parseInt(id))) {
            representativeArray.push({
              type: section.type?.id,
              order:
                section.users?.find(u => parseInt(u.id) === parseInt(id)).pivot
                  ?.order || 1
            });
          }
        });
        setFormData({
          firstname: dataUser.firstname,
          lastname: dataUser.lastname,
          login: dataUser.login,
          email: dataUser.email,
          gender: dataUser.gender,
          birthdate: dataUser.birthdate,
          phone: dataUser.phone,
          mobile_phone: dataUser.mobile_phone,
          profile_picture_url: dataUser.profile_picture_url,
          occupation: dataUser.occupation,
          main_company_id: {
            value: dataUser.main_company ? `${dataUser.main_company.id}` : '',
            label: dataUser.main_company ? dataUser.main_company.name : ''
          },
          main_company_invoices_recipient:
            dataUser.main_company_invoices_recipient,
          secondary_companies: companiesArray,
          roles: rolesArray,
          notifications: notificationsArray,
          signature_file_url: dataUser.signature_file_url,
          is_admin: dataUser.is_admin,
          calendar_access: dataUser.calendar_access,
          courses: coursesArray,
          user_representatives: representativeArray
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        let companiesArray = [];
        responseCompanies.data.map(company => {
          companiesArray.push({
            value: `${company.id}`,
            label: company.name
          });
          company.childrens.map(children => {
            companiesArray.push({
              value: `${children.id}`,
              label: `-- ${children.name}`
            });
          });
        });
        setCompaniesSelect(companiesArray);
        if (searchParams.get('company')) {
          setFormData({
            ...formData,
            main_company_id: parseInt(searchParams.get('company'))
          });
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchRoles = async () => {
      const responseObjects = await IndexRoles();
      if (responseObjects.success === true) {
        let tmpRoles = [];
        responseObjects.data.map(r => {
          tmpRoles.push({
            value: r.id,
            label: r.title
          });
        });
        setRoles(tmpRoles);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCourses = async () => {
      const responseObjects = await IndexCourse('admin');
      if (responseObjects.success === true) {
        setOriginalCourses(responseObjects.data);
        let coursesArray = [];
        responseObjects.data.map(course => {
          coursesArray.push({
            value: `${course.id}`,
            label: course.title
          });
        });
        setCourses(coursesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
    fetchRoles();
    fetchCourses();
    if (id) {
      fetchUser();
    }
  }, [trigger]);

  const deleteObject = async () => {
    setIsLoading(true);
    const response = await DeleteUser(id);
    if (response.success === true) {
      toast('Membre archivé');
      navigate('/administration/membres');
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };

  const handleFieldChange = e => {
    if (
      e.target.name === 'main_company_invoices_recipient' ||
      e.target.name === 'is_admin' ||
      e.target.name === 'calendar_access'
    ) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = false;
    let tmpFormData = formData;
    tmpFormData = {
      ...tmpFormData,
      main_company_id: tmpFormData.main_company_id.value
    };
    if (id) {
      response = await PutUser('admin.updateform', id, tmpFormData);
    } else {
      response = await PostUser(tmpFormData);
    }
    setSuccessForm(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Membre ${response.data.firstname} ${response.data.lastname} enregistrée`;
      toast(successMessage);
      setSuccessForm(successMessage);
      if (!id) {
        setInsertedId(response.data.id);
        setIsLoading(true);
        if (
          (response.data.eventsToAdd && response.data.eventsToAdd.length > 0) ||
          (response.data.templateRecipientsToAdd &&
            response.data.templateRecipientsToAdd.length > 0) ||
          (response.data.recipientsToDelete &&
            response.data.recipientsToDelete.length > 0) ||
          (response.data.templateRecipientsToDelete &&
            response.data.templateRecipientsToDelete.length > 0)
        ) {
          setShowWarningEvent(true);
          setReturnedObject(response.data);
        } else {
          setTimeout(function () {
            window.location.href = `/administration/membres/${
              response.data.id
            }/modifier${
              searchParams.get('back')
                ? `?back=${searchParams.get('back')}`
                : ''
            }`;
          }, 2500);
        }
      } else {
        if (
          (response.data.eventsToAdd && response.data.eventsToAdd.length > 0) ||
          (response.data.templateRecipientsToAdd &&
            response.data.templateRecipientsToAdd.length > 0) ||
          (response.data.recipientsToDelete &&
            response.data.recipientsToDelete.length > 0) ||
          (response.data.templateRecipientsToDelete &&
            response.data.templateRecipientsToDelete.length > 0)
        ) {
          setShowWarningEvent(true);
          setReturnedObject(response.data);
        } else {
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      }
    }
  };

  const manageRecipientToEvent = async (userId, event, action) => {
    let response = await ManageUserToEvent(event.id, {
      context: 'admin.managerecipientfromuser',
      action: action,
      concerned_recipient_user_id: userId
    });
    if (response.success === true) {
      if (action === 'delete') {
        toast("Membre retiré de l'événement");
      } else if (action === 'add') {
        toast("Membre invité à l'événement");
      }
    }
  };

  const manageRecipientToTemplate = async (userId, template, action) => {
    let response = await ManageUserToTemplate(template.id, {
      context: 'admin.managerecipientfromuser',
      action: action,
      concerned_recipient_user_id: userId
    });
    if (response.success === true) {
      if (action === 'delete') {
        toast('Membre retiré de la liste');
      } else if (action === 'add') {
        toast('Membre ajouté à la liste');
      }
    }
  };

  return (
    <>
      {((id && user.id !== null && user.id !== undefined) || !id) && (
        <>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {user.events && user.events.length > 0 && (
                <>
                  <Alert variant="warning">
                    <b>Attention</b>, {formData.firstname} {formData.lastname}{' '}
                    est inscrit(e) à plusieurs événements. Le membre archivé ne
                    sera pas notifié automatiquement de sa suppression. Vous
                    pouvez prévenir le membre hors plateforme si ses invitations
                    sont annulées.
                  </Alert>
                  <Table responsive className="table-stats-tracks">
                    <thead>
                      <tr>
                        <th scope="col">Nom de l'événement</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.events.map((event, index) => (
                        <tr key={index}>
                          <td>{event.title}</td>
                          <td>
                            {Moment(event.start_date).format('DD/MM/YYYY')}{' '}
                            {event.start_date !== event.end_date
                              ? Moment(event.end_date).format('DD/MM/YYYY')
                              : ''}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                disabled={isLoading}
                onClick={() => {
                  deleteObject();
                }}
              >
                Oui, archiver le membre
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <Form onSubmit={handleSubmit}>
            <Row className="g-3">
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col md>
                        <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                          {' '}
                          {user.id !== null && user.id !== undefined
                            ? `Modifier les informations du membre`
                            : 'Ajouter un membre'}
                        </h3>
                      </Col>
                      <Col xs="auto">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            if (searchParams.get('back')) {
                              window.location.href = searchParams.get('back');
                            } else {
                              window.location.href = '/administration/membres';
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          {!id ? 'Annuler' : 'Retour'}
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '4px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {errorForm && (
                  <div className="mt-3">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                {successForm && (
                  <div className="mt-3">
                    <FormAlert
                      className="mt-3"
                      variant="success"
                      data={successForm}
                    />
                  </div>
                )}
              </Col>
              <Col lg={8}>
                {id && user && user.courses.length > 0 && (
                  <Alert variant="info">
                    {user.courses.length === 1 ? (
                      <p className="mb-0">
                        Étudiant inscrit sur la formation{' '}
                        {user.courses[0].title}{' '}
                        {user.courses[0].pivot.course_current_step_id !==
                          null &&
                          user.courses[0].pivot.course_current_step_id !==
                            undefined && (
                            <>
                              (étape en cours :{' '}
                              {
                                user.courses
                                  .filter(c => c.id === user.courses[0].id)[0]
                                  .steps.filter(
                                    s =>
                                      s.id ===
                                      user.courses[0].pivot
                                        .course_current_step_id
                                  )[0].title
                              }
                              )
                            </>
                          )}
                      </p>
                    ) : (
                      <>
                        <p className="mb-0">
                          Étudiant inscrit sur les formations :
                        </p>
                        <ul className="mb-0">
                          {user.courses.map((c, index) => (
                            <li key={index}>
                              {c.title}{' '}
                              {c.pivot.course_current_step_id !== null &&
                              c.pivot.course_current_step_id !== undefined ? (
                                <>
                                  (étape en cours :{' '}
                                  {
                                    user.courses
                                      .filter(co => co.id === c.id)[0]
                                      .steps.filter(
                                        s =>
                                          s.id ===
                                          c.pivot.course_current_step_id
                                      )[0].title
                                  }
                                  )
                                </>
                              ) : (
                                <>(formation non démarrée pour le moment)</>
                              )}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </Alert>
                )}
                <Card className="mb-3 pb-3">
                  <Card.Body className="bg-light">
                    <Row className="gx-2 gy-3">
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="17"
                              height="17"
                              style={{
                                marginRight: '4px',
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                            Avatar par défaut :
                          </Form.Label>
                        </Form.Group>
                        <Row className="pe-3">
                          <Col xs={6}>
                            <img
                              className={
                                formData.gender === 1
                                  ? 'border border-3 border-400 cursor-pointer mw-100'
                                  : 'cursor-pointer mw-100'
                              }
                              src={menAvatar}
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  gender: 1
                                });
                              }}
                            />
                          </Col>
                          <Col xs={6}>
                            <img
                              className={
                                formData.gender === 0
                                  ? 'border border-3 border-400 cursor-pointer mw-100'
                                  : 'cursor-pointer mw-100'
                              }
                              src={womenAvatar}
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  gender: 0
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="17"
                              height="17"
                              style={{
                                marginRight: '4px',
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                            Photo de profil :
                          </Form.Label>
                          {id && user.id ? (
                            <FileUploader
                              pathToUpload="users/profilepics"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="profile_picture_url"
                              cropperEnabled={true}
                              currentImage={user.profile_picture_url}
                            />
                          ) : (
                            <FileUploader
                              pathToUpload="users/profilepics"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="profile_picture_url"
                              cropperEnabled={true}
                            />
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="12" className="pb-2 pt-3">
                        <div className="border-dashed-bottom"></div>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="firstname">
                          <Form.Label>Prénom :</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            placeholder="Prénom du membre"
                            value={formData.firstname || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="lastname">
                          <Form.Label>Nom :</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            placeholder="Nom du membre"
                            value={formData.lastname || ''}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                lastname: e.target.value.toUpperCase()
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="email">
                          <Form.Label>Email :</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Adresse email"
                            value={formData.email || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="phone">
                          <Form.Label>Téléphone fixe :</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            placeholder="Numéro de téléphone"
                            value={formData.phone || ''}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                phone: e.target.value.replace(
                                  /(\d{2})(?=\d)/g,
                                  '$1 '
                                )
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="phone">
                          <Form.Label>Téléphone portable :</Form.Label>
                          <Form.Control
                            type="text"
                            name="mobile_phone"
                            placeholder="Numéro de téléphone portable"
                            value={formData.mobile_phone || ''}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                mobile_phone: e.target.value.replace(
                                  /(\d{2})(?=\d)/g,
                                  '$1 '
                                )
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="occupation">
                          <Form.Label>Date de naissance :</Form.Label>
                          <Form.Control
                            type="date"
                            name="birthdate"
                            value={formData.birthdate || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="occupation">
                          <Form.Label>Fonction(s) :</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="occupation"
                            value={formData.occupation || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12" className="pb-2 pt-3">
                        <div className="border-dashed-bottom"></div>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="main_company_id">
                          <Form.Label>Société principale :</Form.Label>
                          <Select
                            closeMenuOnSelect={true}
                            options={companiesSelect}
                            placeholder="Choisir une société..."
                            name="main_company_id"
                            classNamePrefix="react-select"
                            value={formData.main_company_id}
                            onChange={value => {
                              setFormData({
                                ...formData,
                                main_company_id: value,
                                user_representatives: []
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="secondary_companies">
                          <Form.Label>Société(s) secondaire(s) :</Form.Label>
                          <Select
                            closeMenuOnSelect={false}
                            options={companiesSelect}
                            placeholder="Choisir une ou plusieurs sociétés..."
                            isMulti
                            name="secondary_companies"
                            classNamePrefix="react-select"
                            value={formData.secondary_companies}
                            onChange={value => {
                              setFormData({
                                ...formData,
                                secondary_companies: value
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12" className="pb-2 pt-3">
                        <div className="border-dashed-bottom"></div>
                      </Col>
                      {formData.main_company_id ? (
                        <Col xs={12}>
                          <Form.Group controlId="rep_sections">
                            <Form.Label>
                              Apparaît dans les fonctions de l'entreprise
                              principale (sur la fiche) :
                            </Form.Label>
                          </Form.Group>
                          {formData.user_representatives?.length > 0 ? (
                            <Row>
                              <Col xs={6}>
                                <b>
                                  <small>Fonction</small>
                                </b>
                              </Col>
                              <Col xs={3}>
                                <b>
                                  <small>Classement</small>
                                </b>
                              </Col>
                              <Col xs={3}></Col>
                            </Row>
                          ) : (
                            ''
                          )}
                          {formData.user_representatives?.map(
                            (section, index) => (
                              <Row key={index} className="mb-2">
                                <Col xs={6}>
                                  <Form.Select
                                    aria-label="Type de section"
                                    style={{
                                      width: '100%',
                                      display: 'inline-block',
                                      padding: '0 0 0 3px',
                                      border: '1px solid #ced4da',
                                      fontWeight: '500'
                                    }}
                                    name={`user_representatives[${index}][type]`}
                                    value={
                                      formData.user_representatives[index].type
                                    }
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        user_representatives:
                                          formData.user_representatives.map(
                                            (section, i) =>
                                              i === index
                                                ? {
                                                    ...section,
                                                    type: e.target.value
                                                  }
                                                : section
                                          )
                                      });
                                    }}
                                  >
                                    {types.map((type, i) => (
                                      <option key={i} value={type.id}>
                                        {type.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                                <Col xs={3}>
                                  <Form.Select
                                    aria-label="Ordre"
                                    style={{
                                      width: '100%',
                                      display: 'inline-block',
                                      padding: '0 0 0 3px',
                                      border: '1px solid #ced4da',
                                      fontWeight: '500'
                                    }}
                                    name={`user_representatives[${index}][order]`}
                                    value={
                                      formData.user_representatives[index].order
                                    }
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        user_representatives:
                                          formData.user_representatives.map(
                                            (section, i) =>
                                              i === index
                                                ? {
                                                    ...section,
                                                    order: e.target.value
                                                  }
                                                : section
                                          )
                                      });
                                    }}
                                  >
                                    {[1, 2, 3, 4].map((number, i) => (
                                      <option key={i} value={number}>
                                        {number}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                                <Col xs={3}>
                                  <small>
                                    <a
                                      className="cursor-pointer text-danger"
                                      onClick={() => {
                                        setFormData({
                                          ...formData,
                                          user_representatives:
                                            formData.user_representatives.filter(
                                              (section, i) => i !== index
                                            )
                                        });
                                      }}
                                    >
                                      retirer
                                    </a>
                                  </small>
                                </Col>
                              </Row>
                            )
                          )}
                        </Col>
                      ) : (
                        ''
                      )}
                      {types?.length > 0 ? (
                        <small>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                user_representatives: [
                                  ...formData.user_representatives,
                                  {
                                    type: types[0].id,
                                    order: 1
                                  }
                                ]
                              });
                            }}
                          >
                            + Ajouter
                          </a>
                        </small>
                      ) : (
                        ''
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4}>
                <div className="">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col xs={12}>
                          <Form.Group controlId="login">
                            <Form.Label>Login de connexion :</Form.Label>
                            <Form.Control
                              type="text"
                              name="login"
                              placeholder="Login de connexion"
                              value={formData.login || ''}
                              onChange={handleFieldChange}
                            />
                          </Form.Group>
                        </Col>
                        {!id && (
                          <Col xs={12} className="mt-3">
                            <IconAlert variant="info">
                              <p className="mb-0">
                                Le nouveau membre va recevoir un email
                                automatique avec la procédure de connexion à son
                                compte.
                              </p>
                            </IconAlert>
                          </Col>
                        )}
                        <Col xs={12} className="mt-3">
                          <label>
                            <input
                              type="checkbox"
                              id="main_company_invoices_recipient"
                              name="main_company_invoices_recipient"
                              value="1"
                              className="me-1"
                              checked={
                                formData.main_company_invoices_recipient ===
                                  1 ||
                                formData.main_company_invoices_recipient === '1'
                              }
                              onChange={handleFieldChange}
                            />
                            Reçoit les factures de la société principale ?
                          </label>
                          <span className="d-block smaller-p mb-2">
                            Cocher cette case permet au membre de recevoir les
                            factures adressées à sa{' '}
                            <SoftBadge bg="primary" className="me-2">
                              Société principale
                            </SoftBadge>
                          </span>
                        </Col>
                        {formData.secondary_companies?.length > 0 ? (
                          <Col xs={12} className="mt-3">
                            <label>
                              Reçoit les factures des sociétés secondaires ?
                            </label>
                            {formData.secondary_companies.map(
                              (company, index) => (
                                <div key={index}>
                                  <input
                                    type="checkbox"
                                    id={`secondary_company_${company.value}`}
                                    name={`secondary_company_${company.value}`}
                                    value={company.value}
                                    className="me-1"
                                    checked={formData.secondary_companies.find(
                                      c =>
                                        c.value === company.value &&
                                        c.invoices_recipient === 1
                                    )}
                                    onChange={e => {
                                      let companies =
                                        formData.secondary_companies;
                                      let companyIndex = companies.findIndex(
                                        c => c.value === company.value
                                      );
                                      if (
                                        companyIndex !== null &&
                                        companyIndex !== undefined
                                      ) {
                                        companies[
                                          companyIndex
                                        ].invoices_recipient = e.target.checked
                                          ? 1
                                          : 0;
                                        setFormData({
                                          ...formData,
                                          secondary_companies: companies
                                        });
                                      }
                                    }}
                                  />
                                  {company.label}
                                </div>
                              )
                            )}
                            <span className="d-block smaller-p mb-2">
                              Cocher ces cases permet au membre de recevoir les
                              factures adressées à ses{' '}
                              <SoftBadge bg="secondary" className="me-2">
                                Sociétés secondaires
                              </SoftBadge>
                            </span>
                          </Col>
                        ) : (
                          ''
                        )}
                        <Col md="12" className="pb-2 pt-3">
                          <div className="border-dashed-bottom"></div>
                        </Col>
                        <Col xs={12} className="mt-3">
                          <label>
                            <input
                              type="checkbox"
                              id="is_admin"
                              name="is_admin"
                              value="1"
                              className="me-1"
                              checked={
                                formData.is_admin === 1 ||
                                formData.is_admin === '1'
                              }
                              onChange={handleFieldChange}
                            />
                            Est un administrateur ?
                          </label>
                          <span className="smaller-p d-block mb-2">
                            <SoftBadge bg="warning" className="me-0">
                              Attention
                            </SoftBadge>{' '}
                            Cocher cette case permet au membre d'avoir accès à
                            l'ensemble du panneau d'administration.
                          </span>
                        </Col>
                        <Col md="12" className="pb-2 pt-3">
                          <div className="border-dashed-bottom"></div>
                        </Col>
                        <Col xs={12} className="mt-3">
                          <label>
                            <input
                              type="checkbox"
                              id="calendar_access"
                              name="calendar_access"
                              value="1"
                              className="me-1"
                              checked={
                                formData.calendar_access === 1 ||
                                formData.calendar_access === '1'
                              }
                              onChange={handleFieldChange}
                            />
                            Peut accéder au calendrier ?
                          </label>
                        </Col>
                        <Col md="12" className="pb-2 pt-3">
                          <div className="border-dashed-bottom"></div>
                        </Col>
                        <Col md="12" className="mt-3">
                          <Form.Group controlId="roles">
                            <Form.Label>
                              Rôles <small>(autorisations)</small> :
                            </Form.Label>
                            <Select
                              closeMenuOnSelect={false}
                              options={roles}
                              placeholder="Choisir un ou plusieurs rôles..."
                              isMulti
                              name="roles"
                              classNamePrefix="react-select"
                              value={formData.roles}
                              onChange={value => {
                                setFormData({
                                  ...formData,
                                  roles: value
                                });
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="12" className="mt-3">
                          <Form.Group controlId="notifications">
                            <Form.Label>
                              Reçoit les notifications de :
                            </Form.Label>
                            <Select
                              closeMenuOnSelect={false}
                              options={roles}
                              placeholder="Choisir un ou plusieurs rôles..."
                              isMulti
                              name="notifications"
                              classNamePrefix="react-select"
                              value={formData.notifications}
                              onChange={value => {
                                setFormData({
                                  ...formData,
                                  notifications: value
                                });
                              }}
                            />
                          </Form.Group>
                        </Col>
                        {formData.roles.filter(
                          role => role.label === 'Etudiant'
                        ).length > 0 && (
                          <>
                            <Col md="12" className="pb-2 pt-3 mt-3">
                              <div className="border-dashed-bottom"></div>
                            </Col>
                            <Col md="12" className="mt-3">
                              <Form.Group controlId="roles">
                                <Form.Label>Formation E-learning :</Form.Label>
                                <Select
                                  closeMenuOnSelect={false}
                                  options={courses}
                                  placeholder="Choisir une ou plusieurs formations..."
                                  isMulti
                                  name="courses"
                                  classNamePrefix="react-select"
                                  value={formData.courses}
                                  onChange={value => {
                                    value.map((v, i) => {
                                      if (!v.step_id) {
                                        value[i].step_id = '';
                                      }
                                    });
                                    setFormData({
                                      ...formData,
                                      courses: value
                                    });
                                  }}
                                />
                              </Form.Group>
                              {/*<Form.Group controlId="main_company_id">*/}
                              {/*  <Form.Label>Formation E-learning :</Form.Label>*/}
                              {/*  <Form.Select*/}
                              {/*    aria-label="Formation e-learning"*/}
                              {/*    name="course_id"*/}
                              {/*    value={formData.course_id}*/}
                              {/*    onChange={handleFieldChange}*/}
                              {/*  >*/}
                              {/*    <option disabled={true}>Choisir...</option>*/}
                              {/*    {courses.map((c, index) => (*/}
                              {/*      <option value={c.id} key={index}>*/}
                              {/*        {c.title}*/}
                              {/*      </option>*/}
                              {/*    ))}*/}
                              {/*  </Form.Select>*/}
                              {/*</Form.Group>*/}
                            </Col>
                            {formData.courses && formData.courses.length > 0 && (
                              <Col md="12" className="mt-3">
                                {formData.courses.map((course, index) => (
                                  <div className="smaller-p mb-3" key={index}>
                                    Configurer l'étape actuelle de l'étudiant
                                    sur la formation{' '}
                                    <SoftBadge bg="primary" className="me-0">
                                      {course.label}
                                    </SoftBadge>{' '}
                                    :
                                    <Form.Group className="mt-2">
                                      <Form.Select
                                        size="sm"
                                        aria-label="Étape de la formation"
                                        name="course_current_step_id"
                                        value={
                                          formData.courses.filter(
                                            c => c.value === course.value
                                          )[0].step_id
                                        }
                                        onChange={e => {
                                          let courses = formData.courses;
                                          let courseIndex = courses.findIndex(
                                            c => c.value === course.value
                                          );
                                          if (
                                            courseIndex !== null &&
                                            courseIndex !== undefined
                                          ) {
                                            courses[courseIndex].step_id =
                                              e.target.value;
                                            setFormData({
                                              ...formData,
                                              courses: courses
                                            });
                                          }
                                        }}
                                      >
                                        <option disabled={true}>
                                          Choisir...
                                        </option>
                                        {originalCourses.filter(
                                          c => c.id === parseInt(course.value)
                                        )[0] && (
                                          <Fragment>
                                            {originalCourses
                                              .filter(
                                                c =>
                                                  c.id ===
                                                  parseInt(course.value)
                                              )[0]
                                              .steps.map((step, indexStep) => (
                                                <option
                                                  key={indexStep}
                                                  value={step.id}
                                                >
                                                  {step.title}
                                                </option>
                                              ))}
                                          </Fragment>
                                        )}
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                ))}
                              </Col>
                            )}
                          </>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              </Col>

              <Col xs={12} style={{ marginBottom: '150px' }}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col xs="12">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            if (searchParams.get('back')) {
                              window.location.href = searchParams.get('back');
                            } else {
                              window.location.href = '/administration/membres';
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          {!id ? 'Annuler' : 'Retour'}
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '4px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                        {id && (
                          <div className="float-end">
                            <Button
                              size="sm"
                              variant="falcon-default"
                              className="me-2"
                              onClick={() => {
                                setShowActivities(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="14"
                                height="14"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '3px'
                                }}
                              >
                                <path
                                  d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2a8 8 0 1 0 1.865-5.135L8 9H2V3l2.447 2.446A9.98 9.98 0 0 1 12 2zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"
                                  fill="rgba(100,99,99,1)"
                                />
                              </svg>
                              Activités
                            </Button>
                            <Button
                              size="sm"
                              variant="danger"
                              className="me-0"
                              type="button"
                              onClick={() => {
                                setShowDelete(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="14"
                                height="14"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '3px'
                                }}
                              >
                                <path
                                  d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                  fill="rgba(255,255,255,1)"
                                />
                              </svg>
                              Archiver
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
          <Offcanvas
            show={showActivities}
            onHide={() => setShowActivities(false)}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Activités du membre</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {user.activities?.length > 0 ? (
                user.activities.map((activity, index) => (
                  <div key={index} className="mb-2">
                    <small className="text-muted">
                      {moment(activity.created_at).format('DD/MM/YYYY HH:mm')}
                    </small>
                    <br />
                    {activity.module_label}
                  </div>
                ))
              ) : (
                <p>Aucune activité enregistrée</p>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
      <Modal
        show={showWarningEvent}
        size="lg"
        onHide={() => {
          setShowWarningEvent(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="22"
              height="22"
              style={{
                position: 'relative',
                bottom: '1px',
                marginRight: '2px'
              }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
            </svg>
            Important
          </p>
          {returnedObject.id && (
            <>
              {returnedObject.eventsToAdd &&
                returnedObject.eventsToAdd.length > 0 && (
                  <div className="d-block mb-3">
                    <SoftBadge bg="info" className="me-0 mb-2">
                      Événements - à inviter suite à la modification des rôles
                    </SoftBadge>{' '}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Les autorisations / sociétés de {formData.firstname}{' '}
                          {formData.lastname} viennent d'être modifiées.
                          Veuillez vérifier si cette personne est éligible aux
                          événements ci-dessous :
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>{' '}
                    {returnedObject.eventsToAdd.map((object, index) => (
                      <p className="smaller-p mb-0" key={index}>
                        - {object.title} {object.start_date}{' '}
                        {object.start_date !== object.end_date
                          ? object.end_date
                          : ''}{' '}
                        <a
                          id={`add-${object.id}`}
                          className="cursor-pointer"
                          onClick={() => {
                            manageRecipientToEvent(
                              returnedObject.id,
                              object,
                              'add'
                            );
                            document.getElementById(
                              `add-confirm-${object.id}`
                            ).style.display = 'inline';
                            document
                              .getElementById(`add-${object.id}`)
                              .remove();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{ marginLeft: '4px', marginRight: '2px' }}
                          >
                            <path
                              d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                              fill="#2c7be5"
                            />
                          </svg>
                          ajouter aux destinataires <small>facultatif</small>
                        </a>
                        <span
                          style={{ display: 'none' }}
                          id={`add-confirm-${object.id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{ marginLeft: '4px', marginRight: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Invité(e) à l'événement
                        </span>
                      </p>
                    ))}
                  </div>
                )}
              {returnedObject.recipientsToDelete &&
                returnedObject.recipientsToDelete.length > 0 && (
                  <div>
                    <SoftBadge bg="warning" className="me-0 mb-2">
                      Événements - vérifier les invitations suite à la
                      modification des rôles / notifications
                    </SoftBadge>{' '}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          {formData.firstname} {formData.lastname} est invité(e)
                          à un ou plusieurs événements de la liste ci-dessous.
                          Ses autorisations / sociétés viennent d'être
                          modifiées. Veuillez vérifier que cette personne est
                          toujours éligible aux événements ci-dessous :
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>{' '}
                    {returnedObject.recipientsToDelete.map((object, index) => (
                      <p className="smaller-p mb-0" key={index}>
                        - {object.title} -{' '}
                        {Moment(object.start_date).format('DD/MM/YYYY')}{' '}
                        {object.start_date !== object.end_date
                          ? Moment(object.end_date).format('DD/MM/YYYY')
                          : ''}
                        <a
                          id={`remove-${object.id}`}
                          className="cursor-pointer text-danger"
                          onClick={() => {
                            manageRecipientToEvent(
                              returnedObject.id,
                              object,
                              'delete'
                            );
                            document.getElementById(
                              `remove-confirm-${object.id}`
                            ).style.display = 'inline';
                            document
                              .getElementById(`remove-${object.id}`)
                              .remove();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{ marginLeft: '4px', marginRight: '2px' }}
                          >
                            <path
                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                              fill="#e63757"
                            />
                          </svg>
                          retirer des invités <small>facultatif</small>
                        </a>
                        <span
                          style={{ display: 'none' }}
                          id={`remove-confirm-${object.id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{ marginLeft: '4px', marginRight: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Retiré des invitations
                        </span>
                      </p>
                    ))}
                  </div>
                )}
              {returnedObject.templateRecipientsToAdd &&
                returnedObject.templateRecipientsToAdd.length > 0 && (
                  <div>
                    <SoftBadge bg="info" className="me-0 mb-2">
                      Listes de diffusion - vérifier les inscriptions
                    </SoftBadge>{' '}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Les autorisations / sociétés de {formData.firstname}{' '}
                          {formData.lastname} viennent d'être modifiées.
                          Veuillez vérifier si cette personne est éligible aux
                          listes de diffusion suivantes (modèle de liste de
                          destinataires des événements etquestionnaires) :
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>{' '}
                    {returnedObject.templateRecipientsToAdd.map(
                      (object, index) => (
                        <p className="smaller-p mb-0" key={index}>
                          - {object.title}
                          <a
                            id={`add-template-${object.id}`}
                            className="cursor-pointer"
                            onClick={() => {
                              manageRecipientToTemplate(
                                returnedObject.id,
                                object,
                                'add'
                              );
                              document.getElementById(
                                `add-template-confirm-${object.id}`
                              ).style.display = 'inline';
                              document
                                .getElementById(`add-template-${object.id}`)
                                .remove();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{ marginLeft: '4px', marginRight: '2px' }}
                            >
                              <path
                                d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                fill="#2c7be5"
                              />
                            </svg>
                            ajouter à la liste <small>facultatif</small>
                          </a>
                          <span
                            style={{ display: 'none' }}
                            id={`add-template-confirm-${object.id}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{ marginLeft: '4px', marginRight: '2px' }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                            Ajouté à la liste
                          </span>
                        </p>
                      )
                    )}
                  </div>
                )}
              {returnedObject.templateRecipientsToDelete &&
                returnedObject.templateRecipientsToDelete.length > 0 && (
                  <div>
                    <SoftBadge bg="warning" className="me-0 mb-2">
                      Listes de diffusion - vérifier les inscriptions
                    </SoftBadge>{' '}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          {formData.firstname} {formData.lastname} est présent
                          dans une ou plusieurs listes de diffusion (modèle de
                          liste de destinataires des événements
                          etquestionnaires). Ses autorisations / sociétés
                          viennent d'être modifiées. Veuillez vérifier que cette
                          personne est toujours éligible aux listes de diffusion
                          suivantes :
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>{' '}
                    {returnedObject.templateRecipientsToDelete.map(
                      (object, index) => (
                        <p className="smaller-p mb-0" key={index}>
                          - {object.title}
                          <a
                            id={`remove-template-${object.id}`}
                            className="cursor-pointer text-danger"
                            onClick={() => {
                              manageRecipientToTemplate(
                                returnedObject.id,
                                object,
                                'delete'
                              );
                              document.getElementById(
                                `remove-template-confirm-${object.id}`
                              ).style.display = 'inline';
                              document
                                .getElementById(`remove-template-${object.id}`)
                                .remove();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{ marginLeft: '4px', marginRight: '2px' }}
                            >
                              <path
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                fill="#e63757"
                              />
                            </svg>
                            retirer de la liste <small>facultatif</small>
                          </a>
                          <span
                            style={{ display: 'none' }}
                            id={`remove-template-confirm-${object.id}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{ marginLeft: '4px', marginRight: '2px' }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                            Retiré de la liste
                          </span>
                        </p>
                      )
                    )}
                  </div>
                )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-default"
            size="sm"
            onClick={() => {
              if (!id) {
                window.location.href = `/administration/membres/${insertedId}/modifier${
                  searchParams.get('back')
                    ? `?back=${searchParams.get('back')}`
                    : ''
                }`;
              } else {
                window.location.reload();
              }
            }}
          >
            J'ai vérifié, je valide
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageUsers;
