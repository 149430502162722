import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Collapse,
  Offcanvas
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import {
  IndexAdminUploads,
  ShowAdminUploads
} from '../../../../../services/CompanyService';
import SoftBadge from '../../../../common/SoftBadge';
import IconAlert from '../../../../common/IconAlert';
import { IndexUploadCategory } from '../../../../../services/UploadCategoryService';
import { DeleteUpload, PutUpload } from '../../../../../services/UploadService';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { PutPurchaseCode } from '../../../../../services/PurchaseCodeService';
import { useDispatch, useSelector } from 'react-redux';
import { changeSearch } from '../../../../../redux/slices/filesSlice';

const IndexUploads = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [uploadCategories, setUploadCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedPurchaseCodes, setSelectedPurchaseCodes] = useState(false);
  const [objects, setObjects] = useState([]);
  const [transformedObjects, setTransformedObjects] = useState([]);
  const [transformedPurchaseCodes, setTransformedPurchaseCodes] = useState([]);
  const [initialTransformedObjects, setInitialTransformedObjects] = useState(
    []
  );
  const [showDelete, setShowDelete] = useState(false);
  const [selectedUpload, setSelectedUpload] = useState({});
  const [selectedObject, setSelectedObject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [formData, setFormData] = useStateWithCallbackLazy({
    search: ''
  });
  const filterCategories = [64, 65, 67, 68, 71, 70, 62];
  const [currentCategoryFilter, setCurrentCategoryFilter] = useState({});

  const searchStored = useSelector(state => state.persistedFilesReducer.search);

  const dispatch = useDispatch();

  useEffect(() => {
    setSearchInput(searchStored);
  }, [searchStored]);

  useEffect(() => {
    if (currentCategoryFilter.id) {
      let searchObjects = [];
      searchObjects = initialApiObjects.filter(
        object =>
          object.uploads.filter(
            u =>
              u.upload_category_id === currentCategoryFilter.id &&
              (u.downloaded_at === null || u.downloaded_at === undefined)
          ).length > 0
      );
      setObjects(searchObjects);
    } else {
      applySearch(searchStored, initialApiObjects);
    }
  }, [searchStored, initialApiObjects]);

  useEffect(() => {
    const fetchCompany = async () => {
      const responseObject = await ShowAdminUploads(selectedObject.id);
      if (responseObject.success === true) {
        setSelectedObject(responseObject.data);
      }
    };
    if (selectedObject.id) {
      fetchCompany();
    }
  }, [selectedObject.id]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const responseObjects = await IndexAdminUploads();
      if (responseObjects.success === true) {
        let tmpData = [];
        responseObjects.data.map(company => {
          tmpData.push(company);
          /*if (company.uploads && company.uploads.length > 0) {
            let filtered = [];
            if (currentCategoryFilter.id) {
              filtered = company.uploads.filter(
                c =>
                  c.upload_category_id === currentCategoryFilter.id &&
                  (c.downloaded_at === null || c.downloaded_at === undefined)
              );
            } else {
              filtered = company.uploads.filter(
                c => c.downloaded_at === null || c.downloaded_at === undefined
              );
            }
            if (filtered && filtered.length) {
              company.unreads = filtered.length;
            }
            if (!currentCategoryFilter.id) {
              let filteredPurchaseCodes = company.purchase_codes.filter(
                c => c.is_downloaded === null || c.is_downloaded === 0
              );
              if (filteredPurchaseCodes && filteredPurchaseCodes.length) {
                company.unreads += filteredPurchaseCodes.length;
              }
            }
          } else {
            company.unreads = 0;
          }
          tmpData.push(company);*/
        });
        setInitialApiObjects(tmpData);
        setObjects(tmpData);
        setIsLoading(false);
        setShowDelete(false);
        /*if (selectedObject.id) {
          let tmpUpdatedSelectedObject = tmpData.filter(
            d => d.id === selectedObject.id
          );
          if (tmpUpdatedSelectedObject && tmpUpdatedSelectedObject.length > 0) {
            setSelectedObject(tmpUpdatedSelectedObject[0]);
            if (selectedCategory.id) {
              transformObjects(selectedCategory, tmpUpdatedSelectedObject[0]);
            }
          }
        }*/
        if (selectedObject.id) {
          const fetchCompany = async () => {
            const responseObject = await ShowAdminUploads(selectedObject.id);
            if (responseObject.success === true) {
              setSelectedObject(responseObject.data);
              transformObjects(selectedCategory, responseObject.data);
            }
          };
          fetchCompany();
        }
        if (currentCategoryFilter.id) {
          let searchObjects = [];
          searchObjects = tmpData.filter(
            object =>
              object.uploads.filter(
                u =>
                  u.upload_category_id === currentCategoryFilter.id &&
                  (u.downloaded_at === null || u.downloaded_at === undefined)
              ).length > 0
          );
          setObjects(searchObjects);
        } else {
          applySearch(searchStored, tmpData);
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchUploadCategories = async () => {
      const responseObjects = await IndexUploadCategory();
      if (responseObjects.success === true) {
        setUploadCategories(responseObjects.data);
        let tmpSubCats = [];
        responseObjects.data
          .filter(o => o.parent_id !== null && o.parent_id !== undefined)
          .map(subCat =>
            tmpSubCats.push({
              value: subCat.id,
              label: subCat.title
            })
          );
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    setIsLoading(true);
    fetchUploadCategories();
    fetchCompanies();
  }, [trigger, currentCategoryFilter]);

  const applySearch = (value, objects) => {
    if (value.length > 2) {
      let searchObjects = [];
      searchObjects = objects.filter(
        object =>
          object.name
            .toLowerCase()
            .replace('.', '')
            .indexOf(value.toLowerCase().replace('.', '')) > -1
      );
      setObjects(searchObjects);
    } else {
      if (objects) {
        setObjects(objects);
      } else {
        setObjects(initialApiObjects);
      }
    }
  };

  const updateSearch = (searchTerm, objects) => {
    if (searchTerm.length === 0) {
      setTransformedObjects(objects);
    } else {
      let tmpFilterObjects = objects;
      if (searchTerm.length > 0) {
        let tmpSearch = searchTerm.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (obj.title.toLowerCase().includes(tmpSearch)) {
            result = true;
          }
          return result;
        });
      }
      setTransformedObjects(tmpFilterObjects);
    }
  };

  const getFileExtension = fullPath => {
    let string = fullPath.replace(/^.*[\\/]/, '');
    return string.split('.').pop();
  };

  const downloadUpload = async upload => {
    toast('Démarrage du téléchargement, veuillez patienter...');
    await window.open(upload.file_url);
  };

  const markAsProcessed = async upload => {
    setIsLoading(true);
    let response = await PutUpload(upload.id);
    if (response.success === true) {
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const seenPurchaseCode = async company => {
    setIsLoading(true);
    company.purchase_codes.map(p => {
      PutPurchaseCode(p.id);
    });
    setTrigger(Date.now());
  };

  const countUploads = category => {
    var count = 0;
    initialApiObjects.map(o => {
      count += o.uploads.filter(
        u => u.upload_category_id === category.id
      ).length;
    });
    return count;
  };

  const countUploadsNotReaded = category => {
    var count = 0;
    initialApiObjects.map(o => {
      count += o.uploads.filter(
        u =>
          u.upload_category_id === category.id &&
          (u.downloaded_at === null || u.downloaded_at === undefined)
      ).length;
    });
    return count;
  };

  const transformObjects = (category = null, company = null) => {
    let catObj = {};
    let CompanyObject = {};
    if (category) {
      catObj = category;
    } else {
      catObj = selectedCategory;
    }
    if (company) {
      CompanyObject = company;
    } else {
      CompanyObject = selectedObject;
    }
    let currentUploads = CompanyObject.uploads.filter(
      u =>
        u.upload_category_id === catObj.id ||
        (u.category && u.category.parent_id === catObj.id)
    );
    let transformedApiObjects = [];
    currentUploads.map(obj => {
      transformedApiObjects.push({
        category: obj.category_id,
        title: `${obj.title} ${
          obj.file_url &&
          getFileExtension(obj.file_url) &&
          getFileExtension(obj.file_url).length > 0
            ? `(${getFileExtension(obj.file_url)})`
            : ''
        }`,
        uploadedBy: (
          <>{obj.user && <>{`${obj.user.firstname} ${obj.user.lastname}`}</>}</>
        ),
        created_at: Moment(obj.created_at).local().format('DD/MM/YYYY'),
        downloaded_at: obj.downloaded_at ? (
          Moment(obj.downloaded_at).local().format('DD/MM/YYYY')
        ) : (
          <SoftBadge bg="warning" className="me-2">
            À traiter
          </SoftBadge>
        ),
        actions: (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="overlay-trigger-example">Télécharger</Tooltip>
              }
            >
              <Button
                size="sm"
                variant="primary"
                className="me-2 admin-primary mb-2"
                type="button"
                onClick={() => {
                  downloadUpload(obj);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px'
                  }}
                >
                  <path
                    d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
            {obj.downloaded_at === null && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="overlay-trigger-example">
                    Marquer comme traité
                  </Tooltip>
                }
              >
                <Button
                  size="sm"
                  variant="success"
                  className="me-2 mb-2"
                  type="button"
                  onClick={() => {
                    markAsProcessed(obj);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    style={{
                      marginBottom: '2px'
                    }}
                  >
                    <path
                      d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              overlay={<Tooltip id="overlay-trigger-example">Archiver</Tooltip>}
            >
              <Button
                size="sm"
                variant="danger"
                className="me-0 mb-2"
                type="button"
                onClick={() => {
                  setShowDelete(true);
                  setSelectedUpload(obj);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px'
                  }}
                >
                  <path
                    d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
          </>
        )
      });
    });
    setTransformedObjects(transformedApiObjects);
    setInitialTransformedObjects(transformedApiObjects);
  };
  const transformPurchaseCodes = (codes = null) => {
    let currentPurchaseCodes = codes;
    let transformedApiObjects = [];
    currentPurchaseCodes.map(obj => {
      transformedApiObjects.push({
        title: obj.title,
        quantity: obj.quantity,
        brand: obj.brand ? obj.brand.title : '',
        uploadedBy: (
          <>{obj.user && <>{`${obj.user.firstname} ${obj.user.lastname}`}</>}</>
        ),
        created_at: Moment(obj.created_at).local().format('DD/MM/YYYY')
      });
    });
    setTransformedPurchaseCodes(transformedApiObjects);
  };

  const deleteObject = async () => {
    const response = await DeleteUpload(selectedUpload.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Fichier archivé');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={12}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des fichiers transporteurs
              </h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiObjects.length > 0 && (
        <Row>
          <Col xs={4}>
            <Card className="mt-3">
              <Card.Body className="">
                <Row>
                  {/*{uploadCategories.length > 0 && (
                    <Col xs={12} className="mt-3">
                      {uploadCategories
                        .filter(c => filterCategories.includes(c.id))
                        .filter(c => countUploadsNotReaded(c) > 0)
                        .sort((a, b) => (a.id < b.id ? 1 : -1))
                        .map((category, index) => (
                          <Fragment key={index}>
                            {countUploads(category) > 0 && (
                              <Button
                                onClick={() => {
                                  setSelectedObject({});
                                  setCurrentCategoryFilter(category);
                                  let searchObjects = [];
                                  searchObjects = initialApiObjects.filter(
                                    object =>
                                      object.uploads.filter(
                                        u =>
                                          u.upload_category_id ===
                                            category.id &&
                                          (u.downloaded_at === null ||
                                            u.downloaded_at === undefined)
                                      ).length > 0
                                  );
                                  setObjects(searchObjects);
                                }}
                                aria-controls="category.title"
                                aria-expanded={
                                  selectedCategory.id === category.id
                                }
                                variant="falcon-primary"
                                className={`mb-3 me-2 ${
                                  currentCategoryFilter &&
                                  currentCategoryFilter.id === category.id
                                    ? 'active'
                                    : ''
                                }`}
                                size="sm"
                              >
                                {category.title}
                                <div
                                  className="d-inline"
                                  style={{
                                    position: 'relative',
                                    bottom: '0px'
                                  }}
                                >
                                  <SoftBadge bg="primary" className="me-1 ms-1">
                                    {countUploads(category)}
                                  </SoftBadge>
                                </div>
                                {countUploadsNotReaded(category) > 0 && (
                                  <div
                                    className="d-inline"
                                    style={{
                                      position: 'relative',
                                      bottom: '0px'
                                    }}
                                  >
                                    <SoftBadge
                                      bg="warning"
                                      className="me-0 ms-0"
                                    >
                                      {countUploadsNotReaded(category)}
                                    </SoftBadge>
                                  </div>
                                )}
                              </Button>
                            )}
                          </Fragment>
                        ))}
                    </Col>
                  )}

                  {currentCategoryFilter.id &&
                    uploadCategories
                      .filter(c => filterCategories.includes(c.id))
                      .filter(c => countUploadsNotReaded(c) > 0).length > 0 && (
                      <Col md="12" className="pb-2 mb-2 pt-1">
                        <Button
                          onClick={() => {
                            window.location.reload();
                          }}
                          aria-controls="category.title"
                          variant="falcon-primary"
                          className="mb-3 me-2 border-dashed"
                          size="sm"
                        >
                          X Réinitialiser le filtre
                        </Button>
                        <div className="border-dashed-bottom"></div>
                      </Col>
                    )}*/}

                  <Col xs={12} className="mt-3">
                    <h5 className="admin-title companyRightManagePeople">
                      Choisir une société :
                    </h5>
                  </Col>
                  <Col xs={12} className="mt-3 mb-2">
                    <Form.Control
                      type="search"
                      className="search-input"
                      placeholder="Rechercher quelque chose..."
                      aria-label="Search"
                      value={searchInput}
                      size="sm"
                      onChange={e => {
                        dispatch(changeSearch(e.target.value));
                        setSearchInput(e.target.value);
                        applySearch(e.target.value, initialApiObjects);
                      }}
                    />
                  </Col>
                  <Col xs={12} className="mt-3 mb-2">
                    <Row>
                      {objects.map((object, index) => (
                        <Col
                          key={index}
                          className="cursor-pointer smaller-p mb-1"
                          xs={12}
                          onClick={() => {
                            setSelectedObject(object);
                            setSelectedCategory({});
                            setSelectedPurchaseCodes(false);
                            setTablePageIndex(0);
                            setFormData({
                              search: ''
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          style={{
                            backgroundColor:
                              selectedObject.id &&
                              selectedObject.id === object.id
                                ? '#d5e5fa'
                                : ''
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            style={{
                              marginRight: '3px',
                              marginBottom: '3px',
                              marginLeft: '3px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M12 11V8l4 4-4 4v-3H8v-2h4zm0-9c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 18c4.42 0 8-3.58 8-8s-3.58-8-8-8-8 3.58-8 8 3.58 8 8 8z"
                              fill="rgba(44,123,229,1)"
                            />
                          </svg>
                          {object.name}
                          {object.uploads_count > 0 && (
                            <div
                              className="d-inline"
                              style={{ position: 'relative', bottom: '1px' }}
                            >
                              <SoftBadge bg="warning" className="me-2 ms-1">
                                {object.uploads_count}
                              </SoftBadge>
                            </div>
                          )}
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={8}>
            <Offcanvas
              show={showDelete}
              onHide={() => {
                setShowDelete(false);
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  {selectedUpload.id !== undefined && (
                    <>
                      L'archivage du fichier "{selectedUpload.title}" est
                      définitif.
                    </>
                  )}
                  <br />
                  Voulez-vous continuer ?
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Button
                  size="sm"
                  variant="falcon-danger"
                  className="me-2"
                  disabled={isLoading}
                  onClick={() => {
                    deleteObject();
                  }}
                >
                  Oui, archiver
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowDelete(false);
                  }}
                >
                  Annuler
                </Button>
              </Offcanvas.Body>
            </Offcanvas>
            <div className="">
              <Card className="mt-3">
                <Card.Body className="">
                  <Row>
                    <Col xs={12} className="mt-3">
                      <h5 className="admin-title companyRightManagePeople mb-3">
                        Fichiers{' '}
                        {selectedObject.id && (
                          <>de la société {selectedObject.name}</>
                        )}{' '}
                        :
                      </h5>
                      {!selectedObject.id && (
                        <IconAlert variant="info">
                          <p className="mb-0">
                            Veuillez choisir une société dans la colonne de
                            gauche pour afficher ses fichiers.
                          </p>
                        </IconAlert>
                      )}
                    </Col>
                    {selectedObject.id && uploadCategories.length > 0 && (
                      <>
                        <div className="mt-2">
                          {uploadCategories
                            .filter(c => {
                              if (
                                currentCategoryFilter &&
                                currentCategoryFilter.id
                              ) {
                                return c.id === currentCategoryFilter.id;
                              } else {
                                return true;
                              }
                            })
                            .map((category, index) => (
                              <Fragment key={index}>
                                {selectedObject.uploads &&
                                  selectedObject.uploads.length > 0 &&
                                  selectedObject.uploads.filter(
                                    u => u.upload_category_id === category.id
                                  ).length > 0 && (
                                    <Col xs={12} className="mb-1" key={index}>
                                      <Button
                                        onClick={() => {
                                          setTransformedObjects([]);
                                          setInitialTransformedObjects([]);
                                          setSelectedCategory({});
                                          setTablePageIndex(0);
                                          setFormData({
                                            search: ''
                                          });
                                          if (
                                            selectedCategory.id !== category.id
                                          ) {
                                            setSelectedCategory(category);
                                            transformObjects(category);
                                          }
                                        }}
                                        aria-controls="category.title"
                                        aria-expanded={
                                          selectedCategory.id === category.id
                                        }
                                        variant="falcon-primary"
                                        className={`mb-3 me-2 ${
                                          currentCategoryFilter &&
                                          currentCategoryFilter.id ===
                                            category.id
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        {category.title}
                                        <div
                                          className="d-inline"
                                          style={{
                                            position: 'relative',
                                            bottom: '0px'
                                          }}
                                        >
                                          <SoftBadge
                                            bg="primary"
                                            className="me-1 ms-1"
                                          >
                                            {
                                              selectedObject.uploads.filter(
                                                u =>
                                                  u.upload_category_id ===
                                                    category.id ||
                                                  (u.category &&
                                                    u.category.parent_id ===
                                                      category.id)
                                              ).length
                                            }
                                          </SoftBadge>
                                        </div>
                                        {selectedObject.uploads.filter(
                                          u =>
                                            (u.upload_category_id ===
                                              category.id ||
                                              (u.category &&
                                                u.category.parent_id ===
                                                  category.id)) &&
                                            (u.downloaded_at === null ||
                                              u.downloaded_at === undefined)
                                        ).length > 0 && (
                                          <div
                                            className="d-inline"
                                            style={{
                                              position: 'relative',
                                              bottom: '0px'
                                            }}
                                          >
                                            <SoftBadge
                                              bg="warning"
                                              className="me-0 ms-0"
                                            >
                                              {
                                                selectedObject.uploads.filter(
                                                  u =>
                                                    (u.upload_category_id ===
                                                      category.id ||
                                                      (u.category &&
                                                        u.category.parent_id ===
                                                          category.id)) &&
                                                    (u.downloaded_at === null ||
                                                      u.downloaded_at ===
                                                        undefined)
                                                ).length
                                              }
                                            </SoftBadge>
                                          </div>
                                        )}
                                      </Button>
                                      <Collapse
                                        in={selectedCategory.id === category.id}
                                      >
                                        <div className="border rounded mb-3">
                                          <div className="p-card">
                                            <Row>
                                              <Col xs={6} className="mb-3">
                                                <Form.Label>
                                                  Recherche
                                                </Form.Label>
                                                <Form.Select
                                                  size="sm"
                                                  aria-label="Rechercher"
                                                  name="search"
                                                  value={formData.search || ''}
                                                  onChange={event => {
                                                    setFormData(
                                                      {
                                                        ...formData,
                                                        search:
                                                          event.target.value
                                                      },
                                                      data => {
                                                        updateSearch(data);
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Choisir une sous catégorie
                                                  </option>
                                                  {uploadCategories
                                                    .filter(
                                                      c =>
                                                        c.parent_id ===
                                                        selectedCategory.id
                                                    )
                                                    .map((c, index) => (
                                                      <option
                                                        value={c.title}
                                                        key={index}
                                                      >
                                                        {c.title}
                                                      </option>
                                                    ))}
                                                </Form.Select>
                                              </Col>
                                            </Row>
                                            <Table
                                              data={transformedObjects}
                                              tablePageIndex={tablePageIndex}
                                              setTablePageIndex={
                                                setTablePageIndex
                                              }
                                            />
                                          </div>
                                        </div>
                                      </Collapse>
                                    </Col>
                                  )}
                              </Fragment>
                            ))}
                        </div>
                        {!currentCategoryFilter.id &&
                          selectedObject.purchase_codes &&
                          selectedObject.purchase_codes.length > 0 && (
                            <Fragment>
                              <Col md="12" className="pb-2 mb-2 pt-1">
                                <div className="border-dashed-bottom"></div>
                              </Col>
                              <Col xs={12} className="mb-1">
                                <Button
                                  onClick={() => {
                                    setTransformedObjects([]);
                                    setInitialTransformedObjects([]);
                                    setSelectedCategory({});
                                    setSelectedPurchaseCodes(false);
                                    setTablePageIndex(0);
                                    setFormData({
                                      search: ''
                                    });
                                    if (!selectedPurchaseCodes) {
                                      seenPurchaseCode(selectedObject);
                                      setSelectedPurchaseCodes(true);
                                      transformPurchaseCodes(
                                        selectedObject.purchase_codes
                                      );
                                    }
                                  }}
                                  aria-controls="category.title"
                                  aria-expanded={selectedPurchaseCodes === true}
                                  variant="falcon-primary"
                                  className="mb-3"
                                >
                                  Codes achats
                                  <div
                                    className="d-inline"
                                    style={{
                                      position: 'relative',
                                      bottom: '0px'
                                    }}
                                  >
                                    <SoftBadge
                                      bg="primary"
                                      className="me-1 ms-1"
                                    >
                                      {selectedObject.purchase_codes.length}
                                    </SoftBadge>
                                  </div>
                                  {selectedObject.purchase_codes.filter(
                                    u =>
                                      u.is_downloaded === null ||
                                      u.is_downloaded === 0
                                  ).length > 0 && (
                                    <div
                                      className="d-inline"
                                      style={{
                                        position: 'relative',
                                        bottom: '0px'
                                      }}
                                    >
                                      <SoftBadge
                                        bg="warning"
                                        className="me-0 ms-0"
                                      >
                                        {
                                          selectedObject.purchase_codes.filter(
                                            u =>
                                              u.downloaded_at === null ||
                                              u.downloaded_at === undefined
                                          ).length
                                        }
                                      </SoftBadge>
                                    </div>
                                  )}
                                </Button>
                                <Collapse in={selectedPurchaseCodes === true}>
                                  <div className="border rounded mb-3">
                                    <div className="p-card">
                                      <Table
                                        data={transformedPurchaseCodes}
                                        tablePageIndex={tablePageIndex}
                                        setTablePageIndex={setTablePageIndex}
                                        context="purchaseCodes"
                                      />
                                    </div>
                                  </div>
                                </Collapse>
                              </Col>
                            </Fragment>
                          )}
                      </>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

function Table({ data, tablePageIndex, setTablePageIndex, context = '' }) {
  let columns = null;

  if (context === 'purchaseCodes') {
    columns = React.useMemo(
      () => [
        {
          accessor: 'title',
          Header: 'Code'
        },
        {
          accessor: 'quantity',
          Header: 'Quantité'
        },
        {
          accessor: 'brand',
          Header: 'Marque'
        },
        {
          accessor: 'uploadedBy',
          Header: 'Par'
        },
        {
          accessor: 'created_at',
          Header: 'Le',
          sortType: (a, b) => {
            var a1 = new Date(a).getTime();
            var b1 = new Date(b).getTime();
            if (a1 < b1) return 1;
            else if (a1 > b1) return -1;
            else return 0;
          }
        }
      ],
      []
    );
  } else {
    columns = React.useMemo(
      () => [
        {
          accessor: 'title',
          Header: 'Fichier'
        },
        {
          accessor: 'uploadedBy',
          Header: 'Par'
        },
        {
          accessor: 'created_at',
          Header: 'Le',
          sortType: (a, b) => {
            var a1 = new Date(a).getTime();
            var b1 = new Date(b).getTime();
            if (a1 < b1) return 1;
            else if (a1 > b1) return -1;
            else return 0;
          }
        },
        {
          accessor: 'downloaded_at',
          Header: 'Traité le',
          sortType: (a, b) => {
            var a1 = new Date(a).getTime();
            var b1 = new Date(b).getTime();
            if (a1 < b1) return 1;
            else if (a1 > b1) return -1;
            else return 0;
          }
        },
        {
          accessor: 'actions',
          Header: 'Actions'
        }
      ],
      []
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: tablePageIndex, pageSize: 5 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-0">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            setTablePageIndex(0);
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            setTablePageIndex(pageIndex - 1);
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            setTablePageIndex(pageIndex + 1);
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            setTablePageIndex(pageCount - 1);
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[5, 10, 20, 30, 40].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  tablePageIndex: PropTypes.number,
  setTablePageIndex: PropTypes.func,
  context: PropTypes.string
};

export default IndexUploads;
