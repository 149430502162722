import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavbarVertical from '../components/tredunion/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import RequireAuthAdmin from '../middlewares/RequireAuthAdmin';
import { ShowUser } from '../services/UserService';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import NineDotMenu from '../components/tredunion/navbar/top/NineDotMenu';
import { IndexResourceNotModeratedAdmin } from '../services/ResourceService';
import { IndexEventAdmin } from '../services/Event/EventService';
import { CountInvoicesToProcess } from '../services/Contract/ContractMetaService';

const AuthAdminLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const [resourcesNotModerated, setResourcesNotModerated] = useState(0);
  const [eventNumberIsNotValidated, setEventNumberIsNotValidated] = useState(0);
  const [contractsInvoicesToProcess, setContractsInvoicesToProcess] =
    useState(0);
  const navigate = useNavigate();

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    RequireAuthAdmin(navigate);
    const fetchUser = async () => {
      const responseUser = await ShowUser(
        JSON.parse(localStorage.getItem('user')).id
      );
      if (responseUser.success === true) {
        localStorage.setItem('user', JSON.stringify(responseUser.data));
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchResourcesNotModerated = async () => {
      const responseObjects = await IndexResourceNotModeratedAdmin();
      if (responseObjects.success === true) {
        setResourcesNotModerated(responseObjects.data.length);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchEventNumberIsNotValidated = async () => {
      const responseObjects = await IndexEventAdmin();
      if (responseObjects.success === true) {
        setEventNumberIsNotValidated(
          responseObjects.data.filter(o => o.is_validated === 0 || null).length
        );
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchContractsInvoicesToProcess = async () => {
      const responseObjects = await CountInvoicesToProcess();
      if (responseObjects.success === true) {
        setContractsInvoicesToProcess(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchUser();
    fetchResourcesNotModerated();
    fetchEventNumberIsNotValidated();
    fetchContractsInvoicesToProcess();
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  return (
    <div
      id="espace-administrateur"
      className={isFluid ? 'container-fluid admin' : 'container admin'}
    >
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical
          resourcesNotModerated={resourcesNotModerated}
          eventNumberIsNotValidated={eventNumberIsNotValidated}
          contractsInvoicesToProcess={contractsInvoicesToProcess}
        />
      )}
      <ProductProvider>
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <Row className="justify-content-end">
            <Col xs={4} className="mt-2 pt-0 text-end">
              <NineDotMenu isAdminArea={true} />
            </Col>
          </Row>
          {/*------ Main Routes ------*/}
          <Outlet context={[resourcesNotModerated, setResourcesNotModerated]} />

          {!isKanban && <Footer />}
        </div>
      </ProductProvider>
    </div>
  );
};

export default AuthAdminLayout;
