export const membersRoutes = [
  {
    id: 'tredRessources',
    label: 'Tred Ressources',
    path: '/espace-membre/tred-ressources',
    menus: ['home', 'banner', 'dotTop'],
    description: 'Parcourez les Tred Ressources',
    roles: [],
    exceptRoles: [],
    svg: 'M22 9h-2V7h-8.414l-2-2H4v14h7.447a4.97 4.97 0 0 0 1.664 2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2H21a1 1 0 0 1 1 1v3zm-9 2h9v5.949c0 .99-.501 1.916-1.336 2.465L17.5 21.498l-3.164-2.084A2.953 2.953 0 0 1 13 16.95V11zm2 5.949c0 .316.162.614.436.795l2.064 1.36 2.064-1.36a.954.954 0 0 0 .436-.795V13h-5v3.949z'
  },
  {
    id: 'tredElearning',
    label: 'E-learning',
    path: '/espace-membre/e-learning',
    menus: ['home', 'banner', 'dotTop'],
    description: 'Accédez au catalogue de formations',
    roles: [],
    exceptRoles: [],
    svg: 'M13 21v2h-2v-2H3a1 1 0 0 1-1-1V6h20v14a1 1 0 0 1-1 1h-8zm-9-2h16V8H4v11zm9-9h5v2h-5v-2zm0 4h5v2h-5v-2zm-4-4v3h3a3 3 0 1 1-3-3zM2 3h20v2H2V3z'
  },
  {
    id: 'bilanPedagogique',
    label: 'Bilans pédagogiques',
    path: '/espace-membre/bilans-pedagogiques',
    menus: ['home', 'dotTop'],
    description: 'Retrouvez vos bilans pédagogiques',
    roles: [],
    exceptRoles: [],
    svg: 'M4 11.3333L0 9L12 2L24 9V17.5H22V10.1667L20 11.3333V18.0113L19.7774 18.2864C17.9457 20.5499 15.1418 22 12 22C8.85817 22 6.05429 20.5499 4.22263 18.2864L4 18.0113V11.3333ZM6 12.5V17.2917C7.46721 18.954 9.61112 20 12 20C14.3889 20 16.5328 18.954 18 17.2917V12.5L12 16L6 12.5ZM3.96927 9L12 13.6846L20.0307 9L12 4.31541L3.96927 9Z'
  },
  {
    id: 'tredVisios',
    label: 'Visios',
    description: 'Supports vidéos',
    path: '/espace-membre/visios',
    menus: ['home', 'banner', 'dotTop'],

    roles: [],
    //exceptRoles: [10],
    exceptRoles: [],
    svg: 'M8.17 3A3.001 3.001 0 0 1 11 1h2c1.306 0 2.417.835 2.83 2H21a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h5.17zM4 5v14h16V5h-4.17A3.001 3.001 0 0 1 13 7h-2a3.001 3.001 0 0 1-2.83-2H4zm7-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2zm-1 6l5 3-5 3V9z'
  },
  {
    id: 'tredCodesAchats',
    label: 'Presse spécialisée',
    description: 'La presse spécialisée',
    path: '/espace-membre/presse-specialisee',
    menus: ['home', 'banner', 'dotTop'],
    roles: [1, 10, 2],
    exceptRoles: [],
    svg: 'M4 5v14h16V5H4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 4h3v10H6V7zm4 0h2v10h-2V7zm3 0h1v10h-1V7zm2 0h3v10h-3V7z'
  },
  {
    id: 'ads',
    label: 'Petites annonces',
    description: 'Les petites annonces du groupement',
    path: '/espace-membre/annonces',
    menus: ['home', 'banner', 'dotTop'],
    roles: [],
    exceptRoles: [],
    svg: 'M21 11.6458V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11.6458C2.37764 10.9407 2 10.0144 2 9V3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3V9C22 10.0144 21.6224 10.9407 21 11.6458ZM19 12.874C18.6804 12.9562 18.3453 13 18 13C16.8053 13 15.7329 12.4762 15 11.6458C14.2671 12.4762 13.1947 13 12 13C10.8053 13 9.73294 12.4762 9 11.6458C8.26706 12.4762 7.19469 13 6 13C5.6547 13 5.31962 12.9562 5 12.874V20H19V12.874ZM14 9C14 8.44772 14.4477 8 15 8C15.5523 8 16 8.44772 16 9C16 10.1046 16.8954 11 18 11C19.1046 11 20 10.1046 20 9V4H4V9C4 10.1046 4.89543 11 6 11C7.10457 11 8 10.1046 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9Z'
  },
  {
    id: 'contracts',
    label: 'Contrats Partenaires',
    description: 'Liste des contrats partenaires',
    path: '/espace-membre/contrats',
    menus: ['home', 'banner', 'dotTop'],
    roles: [1, 10, 2],
    exceptRoles: [],
    svg: 'M11.8611 2.39057C12.8495 1.73163 14.1336 1.71797 15.1358 2.35573L19.291 4.99994H20.9998C21.5521 4.99994 21.9998 5.44766 21.9998 5.99994V14.9999C21.9998 15.5522 21.5521 15.9999 20.9998 15.9999H19.4801C19.5396 16.9472 19.0933 17.9102 18.1955 18.4489L13.1021 21.505C12.4591 21.8907 11.6609 21.8817 11.0314 21.4974C10.3311 22.1167 9.2531 22.1849 8.47104 21.5704L3.33028 17.5312C2.56387 16.9291 2.37006 15.9003 2.76579 15.0847C2.28248 14.7057 2 14.1254 2 13.5109V6C2 5.44772 2.44772 5 3 5H7.94693L11.8611 2.39057ZM4.17264 13.6452L4.86467 13.0397C6.09488 11.9632 7.96042 12.0698 9.06001 13.2794L11.7622 16.2518C12.6317 17.2083 12.7903 18.6135 12.1579 19.739L17.1665 16.7339C17.4479 16.5651 17.5497 16.2276 17.4448 15.9433L13.0177 9.74551C12.769 9.39736 12.3264 9.24598 11.9166 9.36892L9.43135 10.1145C8.37425 10.4316 7.22838 10.1427 6.44799 9.36235L6.15522 9.06958C5.58721 8.50157 5.44032 7.69318 5.67935 7H4V13.5109L4.17264 13.6452ZM14.0621 4.04306C13.728 3.83047 13.3 3.83502 12.9705 4.05467L7.56943 7.65537L7.8622 7.94814C8.12233 8.20827 8.50429 8.30456 8.85666 8.19885L11.3419 7.45327C12.5713 7.08445 13.8992 7.53859 14.6452 8.58303L18.5144 13.9999H19.9998V6.99994H19.291C18.9106 6.99994 18.5381 6.89148 18.2172 6.68727L14.0621 4.04306ZM6.18168 14.5448L4.56593 15.9586L9.70669 19.9978L10.4106 18.7659C10.6256 18.3897 10.5738 17.9178 10.2823 17.5971L7.58013 14.6247C7.2136 14.2215 6.59175 14.186 6.18168 14.5448Z'
  },
  {
    id: 'tredFactures',
    label: 'Factures',
    description: 'Accédez à vos factures Tred Union',
    path: '/espace-membre/factures',
    menus: ['home', 'banner', 'dotTop'],
    roles: [1, 2],
    exceptRoles: [],
    svg: 'M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM8 9h8v2H8V9zm0 4h8v2H8v-2z'
  },
  {
    id: 'transfererFichier',
    label: 'Transférer un fichier',
    description: 'Envoyez un fichier à Tred Union',
    path: '/espace-membre/transferer-un-fichier',
    menus: ['home', 'rightBottom', 'dotBottom'],
    roles: [],
    exceptRoles: [],
    svg: 'M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z'
  },
  {
    id: 'trouverMembre',
    label: 'Trouver un membre',
    description: 'Parcourez la base de données',
    path: '/espace-membre/trouver-un-membre',
    menus: ['home', 'rightBottom', 'dotBottom'],
    roles: [],
    exceptRoles: [10],
    svg: 'M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm9.446 9.032l1.504 1.504-1.414 1.414-1.504-1.504a4 4 0 1 1 1.414-1.414zM18 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'
  },
  {
    id: 'exportAnnuaire',
    label: 'Annuaires Tred Union',
    description: 'Accédez à la base de données des entreprises Tred Union',
    path: '/espace-membre/telecharger-annuaire-tred-union',
    menus: ['home', 'rightBottom', 'dotBottom'],
    roles: [],
    exceptRoles: [],
    svg: 'M19 22H5a3 3 0 0 1-3-3V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12h4v4a3 3 0 0 1-3 3zm-1-5v2a1 1 0 0 0 2 0v-2h-2zm-2 3V4H4v15a1 1 0 0 0 1 1h11zM6 7h8v2H6V7zm0 4h8v2H6v-2zm0 4h5v2H6v-2z'
  }
];

export default [membersRoutes];
