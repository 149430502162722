import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'bilans-pedagogiques';

export async function ShowBilansPedagogiques(fromMemberArea = false) {
  return modelService.ShowModel(
    `${model}/${fromMemberArea ? '?fromMemberArea=true' : ''}`
  );
}

export async function GenerateCertificate(formData) {
  return modelService.PostModel(`elearning/attestations/storeEvent`, formData);
}

export async function GenerateCertificates(formData) {
  return modelService.PostModel(
    `elearning/attestations/storeEventBulk`,
    formData
  );
}
