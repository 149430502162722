import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IndexUserAdmin } from '../../../../../services/UserService';
import { Link } from 'react-router-dom';
import TableUsers from './table';
import ExportUserActivities from '../../../../../exports/userActivities';

const IndexUsers = () => {
  const [trigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showExportModal, setShowExportModal] = useState(false);
  const [fromDate, setFromDate] = useState('');

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexUserAdmin();
      if (responseObjects.success === true) {
        setInitialApiObjects(responseObjects.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des membres
              </h3>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                className="w-auto me-2"
                variant="falcon-default"
                size="sm"
                onClick={() => setShowExportModal(true)}
              >
                Exporter les activités
              </Button>
              <Button
                className="w-auto me-2"
                variant="falcon-default"
                size="sm"
                as={Link}
                to="/administration/membres/roles"
              >
                Gestion des rôles
              </Button>
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                as={Link}
                to="/administration/membres/ajouter"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter un membre
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={showExportModal} onHide={() => setShowExportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Exporter les activités</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Date de début (optionnel)</Form.Label>
            <Form.Control
              type="date"
              value={fromDate}
              onChange={e => setFromDate(e.target.value)}
            />
            <Form.Text className="text-muted">
              Laissez vide pour exporter toutes les activités
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowExportModal(false)}>
            Fermer
          </Button>
          <ExportUserActivities fromDate={fromDate} />
        </Modal.Footer>
      </Modal>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiObjects.length > 0 && (
        <TableUsers
          initialApiObjects={initialApiObjects}
          hideInvoiceColumn={true}
        />
      )}
    </>
  );
};

export default IndexUsers;
