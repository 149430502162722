import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  Button,
  Offcanvas,
  OverlayTrigger,
  Table,
  Tooltip
} from 'react-bootstrap';
import ManageLinks from './manage';
import SoftBadge from '../../../../../common/SoftBadge';
import { getFrontUrl } from '../../../../config';
import { DeleteLink } from '../../../../../../services/Survey/LinkService';
import moment from 'moment';

const IndexLinks = ({ survey, setParentTrigger, event }) => {
  const [showIndex, setShowIndex] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const [selectedLink, setSelectedLink] = useState({});
  useEffect(() => {
    //
  }, []);

  const deleteLink = async id => {
    const responseDeleteLink = await DeleteLink(id);
    if (responseDeleteLink.success === true) {
      toast('Lien supprimé');
      setParentTrigger(Date.now());
      setShowDelete(false);
      setSelectedLink({});
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="me-0 mb-3"
        onClick={() => {
          setShowIndex(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          style={{ marginBottom: '2px', marginRight: '2px' }}
        >
          <path
            d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"
            fill="rgba(75,74,77,1)"
          />
        </svg>
        Gérer les liens d'invitation
        {survey.link_recipients && survey.link_recipients.length > 0 && (
          <SoftBadge pill bg="primary" className="ms-1">
            {survey.link_recipients.length}
          </SoftBadge>
        )}
      </Button>

      <ManageLinks
        survey={survey}
        showManage={showManage}
        setShowManage={setShowManage}
        selectedLink={selectedLink}
        setSelectedLink={setSelectedLink}
        setParentTrigger={setParentTrigger}
      />

      <Offcanvas
        show={showIndex}
        onHide={() => {
          setShowIndex(false);
        }}
        placement={'end'}
        style={{ width: '920px' }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Gestion des liens d'invitation{' '}
            <a
              onClick={() => setShowManage(true)}
              className="cursor-pointer ms-2"
            >
              <small>+ Ajouter</small>
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {survey.link_recipients && survey.link_recipients.length > 0 && (
            <Table responsive>
              <thead>
                <tr>
                  <th className="ps-0">Désignation</th>
                  <th>Entreprise</th>
                  <th>Inscriptions</th>
                  <th>Date d'expiration</th>
                  <th>Actif ?</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {survey.link_recipients
                  .sort((a, b) => {
                    if (a.default_company < b.default_company) {
                      return -1;
                    } else {
                      return 1;
                    }
                  })
                  .map((link, index) => (
                    <tr key={index}>
                      <td>
                        {link.label}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="16"
                          height="16"
                          style={{
                            marginBottom: '1px',
                            marginLeft: '3px'
                          }}
                          className="cursor-pointer"
                          onClick={() => {
                            window.open(
                              `${getFrontUrl()}/formulaire-entreprise/${
                                link.uuid
                              }`,
                              '_blank'
                            );
                          }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M10 6V8H5V19H16V14H18V20C18 20.5523 17.5523 21 17 21H4C3.44772 21 3 20.5523 3 20V7C3 6.44772 3.44772 6 4 6H10ZM21 3V11H19L18.9999 6.413L11.2071 14.2071L9.79289 12.7929L17.5849 5H13V3H21Z"
                            fill="rgba(44,123,229,1)"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="16"
                          height="16"
                          style={{
                            marginBottom: '1px',
                            marginLeft: '3px'
                          }}
                          className="cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${getFrontUrl()}/formulaire-entreprise/${
                                link.uuid
                              }`
                            );
                            toast('Lien copié dans le presse-papier');
                          }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M7 4V2H17V4H20.0066C20.5552 4 21 4.44495 21 4.9934V21.0066C21 21.5552 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5551 3 21.0066V4.9934C3 4.44476 3.44495 4 3.9934 4H7ZM7 6H5V20H19V6H17V8H7V6ZM9 4V6H15V4H9Z"
                            fill="rgba(44,123,229,1)"
                          />
                        </svg>
                      </td>
                      <td>{link.default_company}</td>
                      <td>
                        {event ? (
                          <>
                            {link.total_registered}/{link.max_uses}
                          </>
                        ) : (
                          <>
                            {!survey.external_recipients
                              ? 0
                              : survey.external_recipients?.filter(r => {
                                  return (
                                    r.links?.filter(l => l.id === link.id)
                                      .length > 0
                                  );
                                }).length}
                            {link.from_recipients === 0 && (
                              <>/{link.max_uses}</>
                            )}
                          </>
                        )}
                      </td>
                      <td
                        className={
                          moment(link.expires_at).isBefore(moment())
                            ? 'text-danger'
                            : ''
                        }
                      >
                        {moment(link.expires_at).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        {link.is_active === 1 ? (
                          <SoftBadge pill bg="success">
                            Oui
                          </SoftBadge>
                        ) : (
                          <SoftBadge pill bg="primary">
                            Non
                          </SoftBadge>
                        )}
                      </td>
                      <td className="text-end">
                        {link.from_recipients === 0 && (
                          <>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="hi">Modifier</Tooltip>}
                            >
                              <a
                                onClick={() => {
                                  setSelectedLink(link);
                                  setShowManage(true);
                                }}
                                className="cursor-pointer me-3"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="16"
                                  height="16"
                                  style={{
                                    marginBottom: '1px',
                                    marginLeft: '3px'
                                  }}
                                >
                                  <path fill="none" d="M0 0h24v24H0z" />
                                  <path
                                    d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                                    fill="rgba(44,123,229,1)"
                                  />
                                </svg>
                              </a>
                            </OverlayTrigger>
                            <span>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="overlay-trigger-example">
                                    Supprimer le lien
                                  </Tooltip>
                                }
                              >
                                <a
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setSelectedLink(link);
                                    setShowDelete(true);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="14"
                                    height="14"
                                    style={{
                                      marginBottom: '1px',
                                      marginLeft: '3px'
                                    }}
                                  >
                                    <path
                                      d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                      fill="#e63757"
                                    />
                                  </svg>
                                </a>
                              </OverlayTrigger>
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showDelete && selectedLink && selectedLink.id}
        onHide={() => {
          setShowDelete(false);
          setSelectedLink({});
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            La suppression du lien est définitive. Plus personne ne pourra s'y
            inscrire.
            <br />
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteLink(selectedLink.id);
            }}
          >
            Oui, je confirme la suppression
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
              setSelectedLink({});
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

// proptypes :
IndexLinks.propTypes = {
  survey: PropTypes.object.isRequired,
  setParentTrigger: PropTypes.func.isRequired,
  event: PropTypes.object
};
export default IndexLinks;
