import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { GetUserActivities } from '../services/UserActivityService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportUserActivities = ({ fromDate }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);
    const response = await GetUserActivities(fromDate);
    if (response.success && response.data) {
      setData(response.data);
    }
    setLoading(false);
  };

  const handleDownload = () => {
    // Réinitialiser après le téléchargement
    setTimeout(() => {
      setData(null);
    }, 100);
  };

  return (
    <>
      {loading ? (
        <Button variant="falcon-default" size="sm" disabled>
          <FontAwesomeIcon icon={faFileExcel} className="me-1" />
          Chargement...
        </Button>
      ) : data ? (
        <ExcelFile
          filename={`activites-membres_${moment().format('DD-MM-YYYY')}`}
          element={
            <Button variant="success" onClick={handleDownload}>
              <FontAwesomeIcon icon={faFileExcel} className="me-1" />
              Télécharger
            </Button>
          }
        >
          <ExcelSheet data={data.users} name="Activités">
            <ExcelColumn label="NOM" value="lastname" />
            <ExcelColumn label="PRÉNOM" value="firstname" />
            <ExcelColumn label="EMAIL" value="email" />
            <ExcelColumn label="DERNIÈRE ACTIVITÉ" value="last_activity" />
            <ExcelColumn label="MAISON MÈRE" value="main_company" />
            {Object.entries(data.moduleTypes).map(([typeId, label]) => (
              <ExcelColumn
                key={typeId}
                label={label}
                value={row => row[typeId] || 0}
              />
            ))}
          </ExcelSheet>
        </ExcelFile>
      ) : (
        <Button variant="falcon-default" onClick={handleExport}>
          <FontAwesomeIcon icon={faFileExcel} className="me-1" />
          Exporter
        </Button>
      )}
    </>
  );
};

ExportUserActivities.propTypes = {
  fromDate: PropTypes.string
};

export default ExportUserActivities;
