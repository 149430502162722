import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import hours from 'components/tredunion/commons/data/hours';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { IndexAdmin } from '../../../../../services/CompanyService';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import FormAlert from '../../../commons/FormAlert';
import { IndexCategoriesAdminManage } from '../../../../../services/Event/CategoryService';
import {
  PostEvent,
  ShowEvent,
  PutEvent,
  DeclineEvent,
  DeleteEvent
} from '../../../../../services/Event/EventService';
import ModalRecap from './ModalRecap';
import { IndexTypeAdminManage } from '../../../../../services/Event/TypeService';
import { useNavigate, useParams } from 'react-router';
import ManageCategoriesFromEvent from './categories/manageFromEvent';
import ManageTypes from './types/manage';
import SoftBadge from '../../../../common/SoftBadge';
import CanvaDeclineEvent from './CanvaDeclineEvent';
import { useSearchParams } from 'react-router-dom';
import ManageRecipients from './recipients/Manage';
import CanvasDeleteEvent from './CanvaDeteleEvent';
import FileUploader from '../../../commons/fileUploader';
import { IndexEventCategoryGroup } from '../../../../../services/Event/GroupService';
import ManageSurveyModal from '../surveys/ManageModal';

import { Editor } from '@tinymce/tinymce-react';

const ManageEvents = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showFormSurvey, setShowFormSurvey] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showManageCategories, setShowManageCategories] = useState(false);
  const [showManageTypes, setShowManageTypes] = useState(false);
  const [mapCenter, setMapCenter] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(false);
  const [modifyLoc, setModifyLoc] = useState(false);
  const [modifyLink, setModifyLink] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showCanvasDeleteEvent, setShowCanvasDeleteEvent] = useState(false);
  const [showCanvaDeclineEvent, setShowCanvaDeclineEvent] = useState(false); // Ouverture / Fermeture du canva pour delete un event soumis par un adhérent
  const [companiesSelect, setCompaniesSelect] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [categoriesSelect, setCategoriesSelect] = useState([]);
  const [categoryGroupsSelect, setCategoryGroupsSelect] = useState([]);
  const [selectedCategoryGroup, setSelectedCategoryGroup] = useState({});
  const [categoriesfromApi, setCategoriesfromApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [typesSelect, setTypesSelect] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [items, setItems] = useState('');
  const [event, setEvent] = useState({});
  const [trigger, setTrigger] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEvent, setIsLoadingEvent] = useState(false);
  const [participationFormIsCompleted, setParticipationFormIsCompleted] =
    useState(false);
  const [newSurvey, setNewSurvey] = useState(true);
  const [showDeleteSurvey, setShowDeleteSurvey] = useState(false);
  const defaultValues = {
    timeZone: 'GMT-12:00/Etc/GMT-12',
    selectType: '1',
    selectTopic: '1'
  };
  const t = new Date();
  const tm = new Date(t);
  tm.setDate(tm.getDate());
  const [formData, setFormData] = useState({
    context: 'admin.store',
    id: '',
    company_id: '',
    created_by: '',
    title: '',
    description: '',
    banner_picture_url: '',
    event_category_id: '',
    event_type_id: '',
    start_is_all_day: 0,
    is_public: 0,
    location_label: '',
    location_lat: '',
    location_lng: '',
    location_additional_info: '',
    link: '',
    link_code: '',
    max_number_participants: '',
    is_validated: '',
    validated_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    start_date: tm,
    end_date: tm,
    start_time: '14:00',
    end_time: '18:00',
    recipient_roles: [],
    recipient_partner_roles: [],
    recipient_companies: [],
    recipient_users: [],
    recipient_notifications: [],
    recipient_externalUsers: [],
    recipient_informativeUsers: [],
    attachments: []
  });

  let today = new Date();
  let todayPlus30days = new Date();
  todayPlus30days.setDate(today.getDate() + 30);

  const [survey, setSurvey] = useState({
    title: '',
    description: '',
    vimeo_id: '',
    expires_on: todayPlus30days,
    starts_on: Date.now(),
    send_later: 0,
    dunning_starts_on: todayPlus30days,
    survey_dunning: 0,
    survey_dunning_type_id: '',
    dunning_admin_copy_email: '',
    isDeleted: false,
    is_counts_for_points: 1
  });

  const [questions, setQuestions] = useState([
    {
      title: 'Participerez-vous à l’événement ?',
      required: 1,
      type_slug: 'radio',
      isEditable: false,
      answers: ['Oui', 'Non']
    }
  ]);

  const [questionGroups, setQuestionGroups] = useState([]);

  const { register, setValue } = useForm({
    defaultValues
  });

  const handleEditorChange = content => {
    setFormData(prevState => ({
      ...prevState,
      description: content
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    //setDisableValidation(true);
    setSuccessForm(false);
    setIsLoading(false);
    setIsLoadingEvent(true);
    let tmpFormData = { ...formData };
    tmpFormData.start_date = Moment(formData.start_date)
      .local()
      .format('YYYY-MM-DD');
    tmpFormData.end_date = Moment(formData.end_date)
      .local()
      .format('YYYY-MM-DD');
    if (participationFormIsCompleted === true) {
      let tmpSurvey = { ...survey };
      if (tmpSurvey.starts_on) {
        tmpSurvey.starts_on = Moment(tmpSurvey.starts_on)
          .local()
          .set('hour', 0)
          .set('minute', 1)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      if (tmpSurvey.expires_on) {
        tmpSurvey.expires_on = Moment(tmpSurvey.expires_on)
          .local()
          .set('hour', 23)
          .set('minute', 50)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      if (tmpSurvey.dunning_starts_on) {
        tmpSurvey.dunning_starts_on = Moment(tmpSurvey.dunning_starts_on)
          .local()
          .set('hour', 0)
          .set('minute', 1)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      tmpFormData.survey = tmpSurvey;
      tmpFormData.questions = questions;
      tmpFormData.questionGroups = questionGroups;
    }
    tmpFormData.attachments = formData.attachments;
    let response = null;
    if (formData.id && !searchParams.get('duplicate')) {
      tmpFormData.context = 'admin.update';
      tmpFormData.is_validated = 1;
      response = await PutEvent(formData.id, tmpFormData);
    } else {
      tmpFormData.context = 'admin.store';
      response = await PostEvent(tmpFormData);
    }

    if (response.success === true) {
      const successMessage = () =>
        tmpFormData.context === 'admin.update'
          ? 'Modifications effectuées'
          : `Événement ajouté avec succès`;
      toast(successMessage);
      setSuccessForm(successMessage);
      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 250);

      setTimeout(function () {
        window.location.href = `/administration/evenements/${response.data.id}`;
      }, 1500);
    } else {
      toast(
        'Une erreur est survenue, veuillez vérifier votre saisie et que vous avez bien configuré chaque question du formulaire de participation (si concerné)'
      );
      setIsLoading(false);
      //setDisableValidation(false);
    }
    setIsLoadingEvent(false);
  };

  useEffect(() => {
    //
  }, [trigger]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        let companiesArray = [];
        responseCompanies.data.map(company => {
          companiesArray.push({
            value: `${company.id}`,
            label: company.name
          });
          company.childrens.map(children => {
            companiesArray.push({
              value: `${children.id}`,
              label: `-- ${children.name}`
            });
          });
        });
        setCompaniesSelect(companiesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    const fetchCategoriesAdminManage = async () => {
      const responseCategories = await IndexCategoriesAdminManage();
      if (responseCategories.success === true) {
        let categoriesArray = [];
        setCategoriesfromApi(responseCategories.data);
        responseCategories.data.map(category => {
          categoriesArray.push({
            value: category.id,
            originalLabel: category.name,
            label: (
              <>
                <span
                  className="rounded-circle d-inline-block me-2"
                  style={{
                    backgroundColor: category.color,
                    height: '18px',
                    width: '18px',
                    position: 'relative',
                    top: '3px'
                  }}
                ></span>
                {category.name}
                {category.is_admin === 1 && (
                  <small>
                    <SoftBadge bg="primary" className="ms-2 me-2 p-1">
                      cat. interne
                    </SoftBadge>
                  </small>
                )}
              </>
            )
          });
        });
        setCategoriesSelect(categoriesArray);
      } else {
        toast('Une erreur est survenu, veuillez réessayer plus tard');
      }
    };

    const fetchTypesAdminManage = async () => {
      const responseType = await IndexTypeAdminManage();
      if (responseType.success === true) {
        let typeArray = [];
        responseType.data.map(type => {
          typeArray.push({ value: type.id, label: type.name });
        });
        setTypesSelect(typeArray);
      } else {
        toast('Une erreur est survenu, veuillez réessayer plus tard');
      }
    };

    const fetchCategoryGroup = async () => {
      const responseGroup = await IndexEventCategoryGroup();
      if (responseGroup.success === true) {
        let groupArray = [];
        groupArray.push({ value: '', label: 'Ne pas lier à un groupe' });
        responseGroup.data.map(group => {
          groupArray.push({ value: group.id, label: group.name });
        });
        setCategoryGroupsSelect(groupArray);
      } else {
        toast('Une erreur est survenu, veuillez réessayer plus tard');
      }
    };

    const fetchEvent = async () => {
      setIsLoading(true);
      const responseEvent = await ShowEvent(id);
      if (responseEvent.success === true) {
        if (searchParams.get('duplicate')) {
          responseEvent.data.attachments = [];
        }
        setEvent(responseEvent.data);
        let companyId = '';
        if (responseEvent.data.company) {
          setSelectedCompany({
            value: parseInt(responseEvent.data.company_id),
            label: responseEvent.data.company.name
          });
          companyId = parseInt(responseEvent.data.company_id);
        }
        let typeId = '';
        if (responseEvent.data.type) {
          setSelectedType({
            value: parseInt(responseEvent.data.event_type_id),
            label: responseEvent.data.type.name
          });
          typeId = parseInt(responseEvent.data.event_id);
        }
        let categoryId = '';
        if (responseEvent.data.category) {
          setSelectedCategory({
            value: parseInt(responseEvent.data.event_category_id),
            originalLabel: responseEvent.data.category.name,
            label: (
              <>
                <span
                  className="rounded-circle d-inline-block me-2"
                  style={{
                    backgroundColor: responseEvent.data.category.color,
                    height: '18px',
                    width: '18px',
                    position: 'relative',
                    top: '3px'
                  }}
                ></span>

                {responseEvent.data.category.name}
                {responseEvent.data.category.is_admin === 1 && (
                  <small>
                    <SoftBadge bg="primary" className="ms-2 me-2 p-1">
                      cat. interne
                    </SoftBadge>
                  </small>
                )}
              </>
            )
          });
          categoryId = parseInt(responseEvent.data.event_category_id);
        }

        let recipientRolesArray = [];
        let recipientPartnerRolesArray = [];
        let recipientNotificationsArray = [];
        let recipientCompaniesArray = [];
        let recipientUsersArray = [];
        let recipientExternalUsersArray = [];
        let recipientInformativeUsersArray = [];

        if (!searchParams.get('duplicate')) {
          responseEvent.data.role_recipients.map(role => {
            recipientRolesArray.push({
              value: `${role.id}`,
              label: role.title
            });
          });

          responseEvent.data.partner_role_recipients.map(role => {
            recipientPartnerRolesArray.push({
              value: `${role.id}`,
              label: role.title
            });
          });

          responseEvent.data.notification_recipients.map(role => {
            recipientNotificationsArray.push({
              value: `${role.id}`,
              label: role.title
            });
          });

          responseEvent.data.internal_recipients.map(user => {
            recipientUsersArray.push({
              value: `${user.id}`,
              label:
                user.partner && user.partner.id
                  ? `${user.email} - PARTENAIRE - ${user.partner.name}`
                  : `${user.lastname} ${user.firstname} ${
                      user.main_company ? ` - ${user.main_company.name}` : ''
                    }`
            });
          });

          responseEvent.data.external_recipients.map(external => {
            recipientExternalUsersArray.push({
              value: external.id,
              label: `${external.company_name || ''} ${
                external.lastname || ''
              } ${external.firstname || ''} ${external.email}`
            });
          });

          responseEvent.data.informative_recipients.map(email => {
            recipientInformativeUsersArray.push(email.email);
          });

          let tmpRecipients = [];
          if (responseEvent.data.internal_recipients) {
            responseEvent.data.internal_recipients.map(recipient =>
              tmpRecipients.push(recipient)
            );
          }
          if (responseEvent.data.external_recipients) {
            responseEvent.data.external_recipients.map(recipient =>
              tmpRecipients.push(recipient)
            );
          }
          setRecipients(tmpRecipients);
        }

        responseEvent.data.company_recipients.map(company => {
          recipientCompaniesArray.push({
            value: `${company.id}`,
            label: company.name
          });
        });

        setFormData({
          context: 'admin.validate',
          id: searchParams.get('duplicate') ? null : responseEvent.data.id,
          title: searchParams.get('duplicate')
            ? `${responseEvent.data.title} - copie`
            : responseEvent.data.title,
          description: responseEvent.data.description,
          banner_picture_url: responseEvent.data.banner_picture_url,
          event_type_id: responseEvent.data.event_type_id,
          start_is_all_day: responseEvent.data.start_is_all_day,
          is_public: responseEvent.data.is_public,
          location_label: responseEvent.data.location_label,
          location_lat: responseEvent.data.location_lat,
          location_lng: responseEvent.data.location_lng,
          location_additional_info: responseEvent.data.location_additional_info,
          link: responseEvent.data.link,
          link_code: responseEvent.data.link_code,
          max_number_participants: responseEvent.data.max_number_participants,
          is_validated: responseEvent.data.is_validated,
          company_id: companyId,
          event_id: typeId,
          event_category_id: categoryId,
          start_date: new Date(responseEvent.data.start_date),
          end_date: new Date(responseEvent.data.end_date),
          start_time: Moment(responseEvent.data.start_time, 'hh:mm:ss')
            .local()
            .format('LT'),
          end_time: Moment(responseEvent.data.end_time, 'hh:mm:ss').format(
            'LT'
          ),
          recipient_roles: recipientRolesArray,
          recipient_partner_roles: recipientPartnerRolesArray,
          recipient_notifications: recipientNotificationsArray,
          recipient_companies: recipientCompaniesArray,
          recipient_users: recipientUsersArray,
          recipient_externalUsers: recipientExternalUsersArray,
          recipient_informativeUsers: recipientInformativeUsersArray,
          attachments: searchParams.get('duplicate')
            ? []
            : responseEvent.data.attachments
        });
        if (!responseEvent.data.link) {
          setModifyLink(true);
        }
        if (!responseEvent.data.location_label) {
          setModifyLoc(true);
        }
        setMapCenter({
          lat: responseEvent.data.location_lat,
          lng: responseEvent.data.location_lng
        });
        setMarkerPosition({
          lat: responseEvent.data.location_lat,
          lng: responseEvent.data.location_lng
        });

        if (responseEvent.data.participation_form) {
          setNewSurvey(searchParams.get('duplicate') ? true : false);
          setParticipationFormIsCompleted(true);
          setSurvey({
            ...survey,
            id: searchParams.get('duplicate')
              ? null
              : responseEvent.data.participation_form.id,
            title: responseEvent.data.participation_form.title,
            description: responseEvent.data.participation_form.description,
            vimeo_id: responseEvent.data.participation_form.vimeo_id,
            expires_on: searchParams.get('duplicate')
              ? todayPlus30days
              : responseEvent.data.participation_form.expires_on
              ? new Date(responseEvent.data.participation_form.expires_on)
              : todayPlus30days,
            starts_on: searchParams.get('duplicate')
              ? Date.now()
              : responseEvent.data.participation_form.starts_on
              ? new Date(responseEvent.data.participation_form.starts_on)
              : Date.now(),
            send_later: 1,
            dunning_starts_on: searchParams.get('duplicate')
              ? todayPlus30days
              : responseEvent.data.participation_form.dunning_starts_on
              ? new Date(
                  responseEvent.data.participation_form.dunning_starts_on
                )
              : todayPlus30days,
            survey_dunning: searchParams.get('duplicate')
              ? 0
              : responseEvent.data.participation_form.survey_dunning_type_id
              ? 1
              : 0,
            survey_dunning_type_id: searchParams.get('duplicate')
              ? ''
              : responseEvent.data.participation_form.survey_dunning_type_id,
            dunning_admin_copy_email: searchParams.get('duplicate')
              ? ''
              : responseEvent.data.participation_form.dunning_admin_copy_email,
            is_counts_for_points: searchParams.get('duplicate')
              ? 1
              : responseEvent.data.participation_form.is_counts_for_points,
            attachments: searchParams.get('duplicate')
              ? []
              : responseEvent.data.participation_form.attachments?.map(a => {
                  return {
                    attachment_url: a.file_url
                  };
                })
          });
          if (
            responseEvent.data.participation_form.questions &&
            responseEvent.data.participation_form.questions.length > 0
          ) {
            let tmpQuestions = [];
            responseEvent.data.participation_form.questions
              .filter(q => !q.group_id)
              .map((question, index) =>
                tmpQuestions.push({
                  id: searchParams.get('duplicate') ? null : question.id,
                  title: question.title,
                  required: question.required,
                  type_slug: question.type ? question.type.slug : 'text',
                  isEditable: index !== 0,
                  answers: question.answers?.map(a => a.title)
                })
              );
            setQuestions(tmpQuestions);
            if (
              responseEvent.data.participation_form.question_groups &&
              responseEvent.data.participation_form.question_groups.length > 0
            ) {
              let tmpGroups = [];
              responseEvent.data.participation_form.question_groups.map(
                group => {
                  let questions = [];
                  responseEvent.data.participation_form.questions
                    .filter(q => q.group_id && q.group_id === group.id)
                    .map(q => {
                      questions.push({
                        id: searchParams.get('duplicate') ? null : q.id,
                        title: q.title,
                        required: q.required,
                        type_slug: q.type ? q.type.slug : 'text',
                        answers: q.answers?.map(a => a.title)
                      });
                    });
                  tmpGroups.push({
                    id: searchParams.get('duplicate') ? null : group.id,
                    title: group.title,
                    description: group.description,
                    color: group.color,
                    questions: questions
                  });
                }
              );
              setQuestionGroups(tmpGroups);
            }
          }
        }
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (id) {
      fetchEvent();
    }
    fetchCompanies();
    fetchCategoriesAdminManage();
    fetchCategoryGroup();
    fetchTypesAdminManage();
  }, []);

  const customFilterForCategoryFilter = useCallback((category, input) => {
    if (input) {
      if (
        category.data &&
        category.data.originalLabel &&
        category.data.originalLabel
          .toLowerCase()
          .includes(input.toLowerCase().replace('\t', ' '))
      ) {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  const declineEvent = async () => {
    const tmpFormDataDcline = { ...formData };
    const response = await DeclineEvent(tmpFormDataDcline.id);
    if (response.success === true) {
      setShowCanvaDeclineEvent(false);
      toast('Événement refusé');
      setSuccessForm(true);
      setSuccessMessage('Événement refusé');
      setTimeout(function () {
        window.location.href = '/administration/calendrier';
      }, 2000);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const deleteEvent = async () => {
    const response = await DeleteEvent(formData.id);
    if (response.success === true) {
      setShowCanvasDeleteEvent(false);
      toast('Événement supprimé');
      setSuccessForm(true);
      setSuccessMessage('Événement supprimé');
      setTimeout(function () {
        window.location.href = '/administration/calendrier';
      }, 1300);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const checkEventIsAdmin = (form, categories) => {
    let check = categories.filter(
      categories => categories.id === form.event_category_id
    )[0];
    if (check !== null && check !== undefined) {
      return check.is_admin;
    }
  };

  const checkEventIsPast = form => {
    return Moment().diff(form.start_date, 'days') < 1;
  };

  const handleChange = (id, name, value) => {
    const newItems = [...items];
    const updatedItem = { ...newItems[id], [name]: value };
    setItems([
      ...newItems.slice(0, id),
      updatedItem,
      ...newItems.slice(id + 1)
    ]);
  };

  const handleFieldChangeCreate = e => {
    if (e.target.name === 'start_is_all_day') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0,
        end_date: e.target.checked ? formData.end_date : formData.start_date,
        start_time: e.target.checked ? '00:00' : '14:00',
        end_time: e.target.checked ? '23:00' : '18:00'
      });
    } else if (e.target.name === 'is_public') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const position = {
    lat: formData.location_lat,
    lng: formData.location_lng
  };

  const containerStyle = {
    width: '100%',
    height: '250px'
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true
  };
  return (
    <>
      <CanvaDeclineEvent
        showCanvaDeclineEvent={showCanvaDeclineEvent}
        setShowCanvaDeclineEvent={setShowCanvaDeclineEvent}
        declineEvent={declineEvent}
      />
      <CanvasDeleteEvent
        showCanvasDeleteEvent={showCanvasDeleteEvent}
        setShowCanvasDeleteEvent={setShowCanvasDeleteEvent}
        deleteEvent={deleteEvent}
      />

      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                {id
                  ? searchParams.get('duplicate')
                    ? 'Dupliquer'
                    : 'Modifier'
                  : 'Créer'}{' '}
                un événement
              </h3>
            </Col>
            <Col lg={6} className="d-flex justify-content-end">
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  if (searchParams.get('back')) {
                    navigate(searchParams.get('back'));
                  } else {
                    navigate('/administration/calendrier');
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  style={{ marginBottom: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                </svg>
                Annuler
              </Button>
              {id && !searchParams.get('duplicate') && (
                <Button
                  size="sm"
                  variant="falcon-danger"
                  className="me-2"
                  onClick={() => {
                    setShowCanvasDeleteEvent(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    style={{
                      marginBottom: '2px',
                      marginRight: '3px'
                    }}
                  >
                    <path
                      d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                      fill="rgba(237,47,47,1)"
                    />
                  </svg>
                  Supprimer
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {successForm && !errorForm && (
        <div className="mt-3">
          <FormAlert variant="success" data={successMessage} />
        </div>
      )}

      {errorForm && (
        <div className="mt-3">
          <FormAlert variant="warning" data={errorForm} />
        </div>
      )}
      {isLoading === true ? (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <Card className="mt-4">
          <Card.Body className="overflow-hidden px-lg-3">
            {id && event.id && formData.is_validated !== 1 && (
              <Alert variant="warning">
                Cet événement a été soumis par un adhérent depuis son espace.
                <br />
                Vous pouvez <b>valider</b> l'événement en complétant les
                informations ci-dessous puis l'enregistrer en bas de page pour
                le rendre visible sur le calendrier.
                <br />
                Vous pouvez également <b>refuser</b> l'événement en cliquant
                ci-contre :{' '}
                <a
                  onClick={() => {
                    setShowCanvaDeclineEvent(true);
                  }}
                  className="admin-primary pt-0"
                  size="sm"
                  href="#"
                >
                  refuser l'événement
                </a>
                .
              </Alert>
            )}

            <Row className="gx-2 gy-3">
              <Col md="12">
                <Form.Group controlId="title">
                  <Form.Label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="17"
                      height="17"
                      style={{ marginRight: '4px', marginBottom: '2px' }}
                    >
                      <path
                        d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 6V5h-3v2h-2V5H9v2H7V5H4v4h16zm0 2H4v8h16v-8zM6 13h5v4H6v-4z"
                        fill="rgba(100,99,99,1)"
                      />
                    </svg>
                    Titre de l'événement <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    value={formData.title}
                    type="text"
                    name="title"
                    placeholder="Titre"
                    onChange={handleFieldChangeCreate}
                  />
                </Form.Group>
              </Col>
              <Col md="12" className="mt-3">
                <Form.Group controlId="description">
                  <Form.Label>
                    Description{' '}
                    <small>
                      <small>(facultatif, si concerné)</small>
                    </small>
                  </Form.Label>
                  {/*<Form.Control
                    value={formData.description || ''}
                    as="textarea"
                    rows={3}
                    placeholder="Description"
                    name="description"
                    onChange={handleFieldChangeCreate}
                  />*/}
                </Form.Group>
                <Editor
                  value={formData.description}
                  apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                  init={{
                    browser_spellcheck: true,
                    contextmenu: false,
                    language: 'fr_FR',
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                      'link'
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'link',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                  onEditorChange={handleEditorChange}
                />
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="17"
                      height="17"
                      style={{
                        marginRight: '4px',
                        marginBottom: '2px'
                      }}
                    >
                      <path
                        d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                        fill="rgba(100,99,99,1)"
                      />
                    </svg>
                    Photo de couverture <small>facultatif</small>
                  </Form.Label>
                  {id && event.id ? (
                    <FileUploader
                      pathToUpload="events/banners"
                      formData={formData}
                      setFormData={setFormData}
                      format="img"
                      databaseField="banner_picture_url"
                      currentImage={event.banner_picture_url}
                    />
                  ) : (
                    <FileUploader
                      pathToUpload="events/banners"
                      formData={formData}
                      setFormData={setFormData}
                      format="img"
                      databaseField="banner_picture_url"
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md="12" className="pb-3 pt-3">
                <div className="border-dashed-bottom"></div>
              </Col>
              <Col lg="6">
                <Form.Group controlId="location_label">
                  <Form.Label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Lieu <small>(facultatif, si concerné)</small>
                  </Form.Label>
                  {id && !modifyLoc ? (
                    <>
                      <p>
                        {formData.location_label}{' '}
                        <a
                          className="cursor-pointer pb-1 ps-1"
                          onClick={() => {
                            setModifyLoc(true);
                          }}
                        >
                          <small>+ Modifier le lieu</small>
                        </a>
                        <a
                          className="cursor-pointer pb-1 ps-1"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              location_label: '',
                              location_lat: '',
                              location_lng: ''
                            });
                            setModifyLoc(true);
                          }}
                        >
                          <small>x Retirer le lieu</small>
                        </a>
                      </p>
                      <div
                        icon="map-marker-alt"
                        style={{ color: 'red' }}
                        heading="Lieu"
                      >
                        <div className="mb-1" />
                        <div className="d-block mt-2" />
                        {mapCenter !== false && markerPosition !== false && (
                          <GoogleMap
                            center={mapCenter}
                            zoom={12}
                            mapContainerStyle={containerStyle}
                            options={mapOptions}
                          >
                            <></>
                            <Marker position={position} />
                          </GoogleMap>
                        )}
                      </div>
                    </>
                  ) : (
                    <GooglePlacesAutocomplete
                      apiOptions={{
                        language: 'fr',
                        region: 'fr'
                      }}
                      selectProps={{
                        noOptionsMessage: () =>
                          'Tapez un lieu dans la barre de recherche',
                        loadingMessage: () => 'Chargement en cours...',
                        placeholder: 'Commencez à chercher...',
                        onChange: object =>
                          geocodeByPlaceId(object.value.place_id)
                            .then(results =>
                              setFormData({
                                ...formData,
                                location_label: results[0].formatted_address,
                                location_lat:
                                  results[0].geometry.location.lat(),
                                location_lng: results[0].geometry.location.lng()
                              })
                            )
                            .catch(error => console.error(error))
                      }}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group controlId="visio">
                  <Form.Label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 21v-1.07A7.002 7.002 0 0 1 5 13V8a7 7 0 1 1 14 0v5a7.002 7.002 0 0 1-6 6.93V21h4v2H7v-2h4zm1-18a5 5 0 0 0-5 5v5a5 5 0 0 0 10 0V8a5 5 0 0 0-5-5zm0 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Lien de l'événement en ligne{' '}
                    <small>(facultatif, si concerné)</small>
                  </Form.Label>
                  {id && !modifyLink ? (
                    <>
                      <p>
                        {formData.link}{' '}
                        <a
                          className="cursor-pointer pb-1 ps-1"
                          onClick={() => {
                            setModifyLink(true);
                          }}
                        >
                          <small>+ Modifier le lien</small>
                        </a>
                        <a
                          className="cursor-pointer pb-1 ps-1"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              link: ''
                            });
                            setModifyLink(true);
                          }}
                        >
                          <small>+ Retirer le lien</small>
                        </a>
                      </p>
                    </>
                  ) : (
                    <Form.Control
                      type="url"
                      name="link"
                      placeholder="Lien"
                      className="pb-2"
                      value={formData.link || ''}
                      onChange={handleFieldChangeCreate}
                      onBlur={() => {
                        if (
                          formData.link !== null &&
                          formData.link !== undefined &&
                          formData.link
                        ) {
                          if (
                            !formData.link.startsWith('http://') &&
                            !formData.link.startsWith('https://')
                          ) {
                            setFormData({
                              ...formData,
                              link: `https://${formData.link}`
                            });
                          }
                        }
                      }}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col lg="2">
                <Form.Group controlId="visio">
                  <Form.Label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 21v-1.07A7.002 7.002 0 0 1 5 13V8a7 7 0 1 1 14 0v5a7.002 7.002 0 0 1-6 6.93V21h4v2H7v-2h4zm1-18a5 5 0 0 0-5 5v5a5 5 0 0 0 10 0V8a5 5 0 0 0-5-5zm0 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Code du lien
                  </Form.Label>
                  <Form.Control
                    type="url"
                    name="link_code"
                    placeholder="68180"
                    className="pb-2"
                    value={formData.link_code || ''}
                    onChange={handleFieldChangeCreate}
                  />
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group controlId="visio">
                  <Form.Label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Informations additionnelles du lieu{' '}
                    <small>(facultatif, si concerné)</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="location_additional_info"
                    placeholder="Holiday Inn Paris"
                    className="pb-2"
                    value={formData.location_additional_info || ''}
                    onChange={handleFieldChangeCreate}
                  />
                </Form.Group>
              </Col>
              <Col md="12" className="pb-3 pt-3">
                <div className="border-dashed-bottom"></div>
              </Col>
              <Col xs={4} className="mt-3">
                <Form.Group controlId="start_date">
                  <Form.Label>
                    {' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm-2 2H9v2H7V5H4v4h16V5h-3v2h-2V5zm5 6H4v8h16v-8z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Date {formData.start_is_all_day === 1 && ' de début'}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <DatePicker
                    showYearDropdown
                    name="start_date"
                    dateFormat="dd/MM/yyyy"
                    locale="fr"
                    selected={formData.start_date}
                    onChange={date => {
                      let tomorrow = new Date(date);
                      tomorrow.setDate(tomorrow.getDate() + 1);
                      let expiresOn = new Date(date);
                      expiresOn.setDate(expiresOn.getDate() - 14);
                      let dunningStartsOn = new Date(date);
                      dunningStartsOn.setDate(dunningStartsOn.getDate() - 30);
                      let tmpFormData = {
                        ...formData,
                        start_date: date,
                        end_date: date
                      };
                      setFormData(tmpFormData);
                      setSurvey({
                        ...survey,
                        expires_on: expiresOn,
                        dunning_starts_on: dunningStartsOn
                      });
                    }}
                    formatWeekDay={startDate => startDate.slice(0, 3)}
                    className="form-control"
                    placeholderText="Date de début"
                    value={formData.start_date}
                  />
                </Form.Group>
                <Form.Group className="mt-2" controlId="start_is_all_day">
                  <Form.Label>
                    Plusieurs jours ?
                    <Form.Check
                      className="d-inline-block ms-2"
                      style={{ fontSize: 'medium' }}
                      checked={
                        formData.start_is_all_day === 1 ||
                        (formData.start_is_all_day === '1' &&
                          formData.start_is_all_day)
                      }
                      type="checkbox"
                      id="defaultSwitch"
                      value={formData.start_is_all_day}
                      name="start_is_all_day"
                      onChange={handleFieldChangeCreate}
                    />
                  </Form.Label>
                </Form.Group>
              </Col>
              {formData.start_is_all_day === 1 && (
                <Col xs={4} className="mt-3">
                  <Form.Group controlId="end_date">
                    <Form.Label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm-2 2H9v2H7V5H4v4h16V5h-3v2h-2V5zm5 6H4v8h16v-8z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Date de fin <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <DatePicker
                      showYearDropdown
                      name="end_date"
                      dateFormat="dd/MM/yyyy"
                      locale="fr"
                      selected={formData.end_date}
                      onChange={date => {
                        let tmpFormData = { ...formData, end_date: date };
                        setFormData(tmpFormData);
                      }}
                      className="form-control"
                      placeholderText="Date de fin"
                      value={formData.end_date}
                    />
                  </Form.Group>
                </Col>
              )}

              <Col
                xs={formData.start_is_all_day === 0 ? 4 : 12}
                className="mt-3"
              >
                <Form.Group controlId="start_time">
                  <Form.Label>
                    {' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Heure de début
                  </Form.Label>
                  <Form.Select
                    name="start_time"
                    onChange={handleFieldChangeCreate}
                    value={formData.start_time}
                  >
                    {hours.map((hour, index) => (
                      <option key={index} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col
                xs={formData.start_is_all_day === 0 ? 4 : 12}
                className="mt-3"
              >
                <Form.Group controlId="end_time">
                  <Form.Label>
                    {' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Heure de fin
                  </Form.Label>
                  <Form.Select
                    name="end_time"
                    onChange={handleFieldChangeCreate}
                    value={formData.end_time}
                  >
                    {hours.map((hour, index) => (
                      <option key={index} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {checkEventIsAdmin(formData, categoriesfromApi) !== 1 &&
              checkEventIsPast(formData) && (
                <>
                  <Col md="12" className="pb-3 pt-3">
                    <div className="border-dashed-bottom"></div>
                  </Col>

                  <Row>
                    <Col className="pt-2 pb-2" md="12">
                      <div>
                        <Row>
                          <Col md="auto">
                            <Form.Label>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M4 2h16a1 1 0 0 1 1 1v19.276a.5.5 0 0 1-.704.457L12 19.03l-8.296 3.702A.5.5 0 0 1 3 22.276V3a1 1 0 0 1 1-1zm15 17.965V4H5v15.965l7-3.124 7 3.124zM12 13.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L12 6l1.47 2.977 3.285.478-2.377 2.318.56 3.272L12 13.5z"
                                  fill="rgba(94,110,130,1)"
                                />
                              </svg>{' '}
                              Événement public ?
                            </Form.Label>
                          </Col>
                          <Col md="auto" className="ps-0">
                            <Form.Group controlId="is_public">
                              <input
                                type="checkbox"
                                id="is_public"
                                name="is_public"
                                value="1"
                                className="me-1"
                                checked={
                                  formData.is_public === 1 ||
                                  formData.is_public === '1'
                                }
                                style={{
                                  position: 'relative',
                                  left: '-5px',
                                  top: '2px'
                                }}
                                onChange={handleFieldChangeCreate}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="2"></Col>
                        </Row>
                        <SoftBadge bg="primary">
                          Affichage sur le calendrier de la page d’accueil du
                          site
                        </SoftBadge>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

            <Col md="12" className="pb-3 pt-3">
              <div className="border-dashed-bottom"></div>
            </Col>

            <Col lg="6">
              <Form.Group controlId="location_label">
                <Form.Label>
                  Nombre maximum d'inscriptions via le formulaire de
                  participation <small>(facultatif, si concerné)</small>
                </Form.Label>
                <Form.Control
                  value={formData.max_number_participants}
                  type="number"
                  name="max_number_participants"
                  placeholder="10"
                  onWheel={e => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  onChange={handleFieldChangeCreate}
                />
              </Form.Group>
            </Col>

            <Col md="12" className="pb-3 pt-3">
              <div className="border-dashed-bottom"></div>
            </Col>

            <Col md="12" className="mt-2">
              <Row>
                <Col md="6">
                  <Form.Group controlId="company_id">
                    <Form.Label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="17"
                        height="17"
                        style={{ marginRight: '4px', marginBottom: '2px' }}
                      >
                        <path
                          d="M3 19V5.7a1 1 0 0 1 .658-.94l9.671-3.516a.5.5 0 0 1 .671.47v4.953l6.316 2.105a1 1 0 0 1 .684.949V19h2v2H1v-2h2zm2 0h7V3.855L5 6.401V19zm14 0v-8.558l-5-1.667V19h5z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                      Société concernée{' '}
                    </Form.Label>{' '}
                    <small>(facultatif, si concerné)</small>
                    {selectedCompany && (
                      <div className="mb-2">
                        <small>
                          <a
                            className="cursor-pointer pb-1 ps-1"
                            onClick={() => {
                              setFormData(
                                {
                                  ...formData,
                                  company_id: null
                                },
                                setSelectedCompany(null)
                              );
                            }}
                          >
                            <small>x Retirer</small>
                          </a>
                        </small>
                      </div>
                    )}
                    <Select
                      closeMenuOnSelect={true}
                      options={companiesSelect}
                      placeholder="Choisir une société..."
                      name="company_id"
                      classNamePrefix="react-select"
                      value={selectedCompany}
                      onChange={object => {
                        setFormData({
                          ...formData,
                          company_id: parseInt(object.value)
                        });
                        setSelectedCompany(object);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group controlId="event_category_id">
                    <Row className="justify-content-center">
                      <Col xs={12} className="justify-content-center">
                        <Form.Label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="17"
                            height="17"
                            style={{ marginRight: '4px', marginBottom: '2px' }}
                          >
                            <path
                              d="M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm.707 2.122L3.828 12l8.486 8.485 7.778-7.778-1.06-7.425-7.425-1.06zm2.12 6.364a2 2 0 1 1 2.83-2.829 2 2 0 0 1-2.83 2.829z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Catégorie de l’événement{' '}
                          <span style={{ color: 'red' }}>*</span>
                          <a
                            className="cursor-pointer pb-1 ps-2"
                            onClick={() => {
                              setShowManageCategories(true);
                            }}
                          >
                            <small>+ Ajouter une catégorie</small>
                          </a>{' '}
                          <ManageCategoriesFromEvent
                            showManageCategories={showManageCategories}
                            setShowManageCategories={setShowManageCategories}
                            setCategoriesSelect={setCategoriesSelect}
                            setSelectedCategory={setSelectedCategory}
                            parentFormData={formData}
                            setParentFormData={setFormData}
                            categoryGroupsSelect={categoryGroupsSelect}
                            selectedCategoryGroup={selectedCategoryGroup}
                            setSelectedCategoryGroup={setSelectedCategoryGroup}
                          />
                        </Form.Label>
                      </Col>
                    </Row>
                    <Select
                      filterOption={customFilterForCategoryFilter}
                      closeMenuOnSelect={true}
                      placeholder="Choisir une catégorie..."
                      options={categoriesSelect}
                      name="event_category_id"
                      classNamePrefix="react-select"
                      onChange={object => {
                        let check = categoriesfromApi.filter(
                          categories => categories.id === parseInt(object.value)
                        )[0];
                        if (check !== null && check !== undefined) {
                          if (check.is_admin) {
                            setFormData({
                              ...formData,
                              event_category_id: parseInt(object.value),
                              is_public: 0
                            });
                          } else {
                            setFormData({
                              ...formData,
                              event_category_id: parseInt(object.value)
                            });
                          }
                        }
                        setSelectedCategory(object);
                      }}
                      value={selectedCategory}
                    />
                  </Form.Group>
                </Col>
                <Col md="6" className="mt-3">
                  <Form.Group controlId="event_type_id">
                    <Form.Label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="17"
                        height="17"
                        style={{ marginRight: '4px', marginBottom: '2px' }}
                      >
                        <path
                          d="M2 18h7v2H2v-2zm0-7h9v2H2v-2zm0-7h20v2H2V4zm18.674 9.025l1.156-.391 1 1.732-.916.805a4.017 4.017 0 0 1 0 1.658l.916.805-1 1.732-1.156-.391c-.41.37-.898.655-1.435.83L19 21h-2l-.24-1.196a3.996 3.996 0 0 1-1.434-.83l-1.156.392-1-1.732.916-.805a4.017 4.017 0 0 1 0-1.658l-.916-.805 1-1.732 1.156.391c.41-.37.898-.655 1.435-.83L17 11h2l.24 1.196c.536.174 1.024.46 1.434.83zM18 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                      Typologie de l’événement{' '}
                      <span style={{ color: 'red' }}>*</span>
                      <a
                        className="cursor-pointer pb-1 ps-2"
                        onClick={() => {
                          setShowManageTypes(true);
                        }}
                      >
                        <small>+ Ajouter une typologie</small>
                      </a>{' '}
                      <ManageTypes
                        showManageTypes={showManageTypes}
                        setShowManageTypes={setShowManageTypes}
                        setTypesSelect={setTypesSelect}
                        setSelectedType={setSelectedType}
                        parentFormData={formData}
                        setParentFormData={setFormData}
                      />
                    </Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      placeholder="Choisir la typologie..."
                      options={typesSelect}
                      name="event_type_id"
                      classNamePrefix="react-select"
                      onChange={object => {
                        setFormData({
                          ...formData,
                          event_type_id: parseInt(object.value)
                        });
                        setSelectedType(object);
                      }}
                      value={selectedType}
                    />
                    {checkEventIsAdmin(formData, categoriesfromApi) !== 1 &&
                    checkEventIsPast(formData) ? (
                      ''
                    ) : (
                      <div className="mt-8"></div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col md="12" className="pb-4 pt-3 mt-3">
                <div className="border-dashed-bottom"></div>
              </Col>
            </Row>
            {checkEventIsAdmin(formData, categoriesfromApi) !== 1 &&
              checkEventIsPast(formData) && (
                <ManageRecipients
                  formData={formData}
                  setFormData={setFormData}
                  isEvent={true}
                />
              )}
            {checkEventIsAdmin(formData, categoriesfromApi) !== 1 &&
              checkEventIsPast(formData) && (
                /*(formData.recipient_roles.length > 0 ||
                formData.recipient_companies.length > 0 ||
                formData.recipient_users.length > 0 ||
                formData.recipient_externalUsers.length > 0 ||
                formData.recipient_informativeUsers.length > 0)*/ <>
                  <Row>
                    <Col md="12" className="pb-4">
                      <div className="border-dashed-bottom"></div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xs={12}>
                      <Form.Label>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="17"
                          height="17"
                          style={{ marginRight: '4px', marginBottom: '2px' }}
                        >
                          <path
                            d="M2 18h7v2H2v-2zm0-7h9v2H2v-2zm0-7h20v2H2V4zm18.674 9.025l1.156-.391 1 1.732-.916.805a4.017 4.017 0 0 1 0 1.658l.916.805-1 1.732-1.156-.391c-.41.37-.898.655-1.435.83L19 21h-2l-.24-1.196a3.996 3.996 0 0 1-1.434-.83l-1.156.392-1-1.732.916-.805a4.017 4.017 0 0 1 0-1.658l-.916-.805 1-1.732 1.156.391c.41-.37.898-.655 1.435-.83L17 11h2l.24 1.196c.536.174 1.024.46 1.434.83zM18 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                            fill="rgba(100,99,99,1)"
                          />
                        </svg>
                        Formulaire de participation <small>facultatif</small>
                      </Form.Label>
                    </Col>
                    <Col xs={12} className="pt-3">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        onClick={() => {
                          setShowFormSurvey(true);
                          setParticipationFormIsCompleted(true);
                          setSurvey({ ...survey, isDeleted: false });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '2px' }}
                        >
                          {participationFormIsCompleted === true ? (
                            <path d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z" />
                          ) : (
                            <path d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                          )}
                        </svg>
                        {participationFormIsCompleted === true
                          ? 'Modifier le formulaire de participation'
                          : 'Ajouter un formulaire de participation'}
                      </Button>
                      {participationFormIsCompleted === true && (
                        <Button
                          size="sm"
                          variant="danger"
                          className="ms-2"
                          onClick={() => {
                            setShowDeleteSurvey(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '2px' }}
                          >
                            <path
                              d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Supprimer
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="pb-4">
                      <div className="border-dashed-bottom"></div>
                    </Col>
                  </Row>
                </>
              )}
            <Col xs={12}>
              <Form.Label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="17"
                  height="17"
                  style={{ marginRight: '4px', marginBottom: '2px' }}
                >
                  <path
                    d="M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784L18 17a6 6 0 0 0-11.996-.225L6 17v1.978a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z"
                    fill="rgba(100,99,99,1)"
                  />
                </svg>
                Ajouter des pièces jointes <small>facultatif</small>{' '}
                <OverlayTrigger
                  overlay={
                    <Tooltip id="overlay-trigger-example">
                      Ces pièces jointes seront envoyées dans les mails de
                      notification de l'événement
                    </Tooltip>
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      fill="rgba(100,99,99,1)"
                    />
                  </svg>
                </OverlayTrigger>
              </Form.Label>
              {event.attachments && (
                <>
                  {event.attachments.map((attachment, index) => (
                    <Row key={index} className="align-items-center">
                      <Col xs={5} className="pt-2">
                        <FileUploader
                          pathToUpload="events/attachments"
                          formData={formData}
                          setFormData={setFormData}
                          format="any"
                          databaseField="file_url"
                          databaseFieldArray="attachments"
                          databaseFieldArrayIndex={index}
                          cropperEnabled={false}
                          progressBar={true}
                          currentImage={attachment.file_url || null}
                        />
                      </Col>
                      <Col xs={5} className="pt-2 px-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          className="cursor-pointer"
                          onClick={() => {
                            const newAttachments = event.attachments.filter(
                              (attachment, i) => i !== index
                            );
                            setEvent({
                              ...event,
                              attachments: newAttachments
                            });
                          }}
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                            fill="#e52f12"
                          />
                        </svg>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
              <Row>
                <Col xs={12} className="pt-2">
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="mt-2 mb-3"
                    onClick={() => {
                      let tmpEvent = event;
                      if (!tmpEvent.attachments) {
                        tmpEvent.attachments = [];
                      }
                      tmpEvent.attachments.push({
                        file_url: ''
                      });
                      setEvent(tmpEvent);
                      setTrigger(Date.now());
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                        fill="rgba(77,89,105,1)"
                      />
                    </svg>{' '}
                    Ajouter une pièce jointe
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="pb-2 pt-2 mb-2">
              <div className="border-dashed-bottom"></div>
            </Col>
            <Col xs="auto" className="d-flex mb-2">
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                disabled={isLoadingEvent}
                onClick={handleSubmit}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>{' '}
                Enregistrer l'événement
              </Button>
              {isLoadingEvent && (
                <Spinner
                  animation="border"
                  variant="primary"
                  size="sm"
                  className="ms-2 mt-1"
                />
              )}
            </Col>
            <ModalRecap
              modalShow={modalShow}
              setModalShow={setModalShow}
              formData={formData}
              selectedType={selectedType}
              selectedCategory={selectedCategory}
              survey={survey}
              questions={questions}
              questionGroups={questionGroups}
              participationFormIsCompleted={participationFormIsCompleted}
              event={event}
              setFormData={setFormData}
            />

            <ManageSurveyModal
              showFormSurvey={showFormSurvey}
              setShowFormSurvey={setShowFormSurvey}
              questions={questions}
              setQuestions={setQuestions}
              survey={survey}
              setSurvey={setSurvey}
              formData={formData}
              handleChange={handleChange}
              defaultValues={defaultValues}
              register={register}
              setValue={setValue}
              recipients={recipients}
              newSurvey={newSurvey}
              questionGroups={questionGroups}
              setQuestionGroups={setQuestionGroups}
              event={true}
            />
          </Card.Body>
        </Card>
      )}
      <Offcanvas
        show={showDeleteSurvey}
        onHide={() => {
          setShowDeleteSurvey(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            La suppression du formulaire de participation est définitive et
            supprimera également les réponses de tous les participants qui ont
            déjà répondu.
            <br />
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            disabled={isLoading}
            onClick={() => {
              setParticipationFormIsCompleted(false);
              setSurvey({
                title: '',
                description: '',
                vimeo_id: '',
                expires_on: todayPlus30days,
                starts_on: Date.now(),
                send_later: 0,
                dunning_starts_on: todayPlus30days,
                survey_dunning: 0,
                survey_dunning_type_id: '',
                dunning_admin_copy_email: '',
                isDeleted: true,
                is_counts_for_points: 1,
                attachment_url: ''
              });
              setQuestions([
                {
                  title: 'Participerez-vous à l’événement ?',
                  required: 1,
                  type_slug: 'radio',
                  isEditable: false,
                  answers: ['Oui', 'Non']
                }
              ]);
              setShowDeleteSurvey(false);
              toast(
                "Formulaire supprimé. Pour annuler, rechargez cette page avant d'enregistrer l'événement."
              );
            }}
          >
            Oui, je confirme la suppression
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDeleteSurvey(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManageEvents;
