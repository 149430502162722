import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import RequireAuthMembre from '../middlewares/RequireAuthMembre';
import NavbarStandard from '../components/tredunion/commons/NavbarStandard';
import MemberBanner from '../components/tredunion/authpages/members/global/banner';
import FooterStandard from '../components/tredunion/commons/FooterStandard';
import Section from '../components/common/Section';
import { Col, Row } from 'react-bootstrap';
import { ShowUser } from '../services/UserService';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import MemberSide from './MemberSide';

const AuthMembreLayout = ({ isHome }) => {
  const [trigger, setTrigger] = useState(Date.now);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    RequireAuthMembre(navigate);
    const fetchUser = async () => {
      const responseUser = await ShowUser(
        JSON.parse(localStorage.getItem('user')).id
      );
      if (responseUser.success === true) {
        localStorage.setItem('user', JSON.stringify(responseUser.data));
        setUser(responseUser.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchUser();
  }, [trigger]);
  // const location = useLocation();
  /*const usePathname = () => {
    return location.pathname.replace(/\/+$/, '');
  };
  function pluck(array, key) {
    return array.map(o => o[key]);
  }*/
  return (
    <>
      {user.id !== null && user.id !== undefined && (
        <div id="espace-membre">
          <NavbarStandard />
          <MemberBanner user={user} trigger={trigger} setTrigger={setTrigger} />
          <Section className="d-flex py-4" id="espace-membre-content">
            <Row>
              <Col lg={isHome ? 12 : 8}>
                <Outlet context={[user, setUser]} />
              </Col>
              {!isHome && <MemberSide user={user} />}
            </Row>
          </Section>
          <FooterStandard />
        </div>
      )}
    </>
  );
};

AuthMembreLayout.propTypes = {
  isHome: PropTypes.any
};

export default AuthMembreLayout;
