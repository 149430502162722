import ModelService from '../ModelService';
const modelService = new ModelService();

const model = 'points';

export async function PostPoint(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutPoint(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeletePoint(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function ExportPoints(year) {
  return modelService.IndexModel(`${model}/export?year=${year}`);
}
